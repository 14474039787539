export const exportCsv = (data) => {
  let csvContent = 'data:text/csv;charset=utf-8,'
  var universalBOM = '\uFEFF'
  csvContent += universalBOM
  csvContent +=
    // 'Tipas;Kliento kodas;Valstybinis numeris;Apdraustų dienų skaičius;Įvykių skaičius;Įvykiai;\n'
    // 'Stage;Claim;Client;VIN;ICN;Unloading Date;Registration;Reported Date;Driver;Description;Location;Paid;Duration;Followers\n'
    'Stage;Stage Change Date;Client;Insurance Provider;Claim Number;Insurance claim number;Register in Insurance;Reported date;Unloading Date;Incident date;Declared for insurer date;Insured Vehicle;Driver;Reserve;Reserve received;Amount claimed;Cargo type;Damaged cargo;VIN;Damaged vehicle parts;Make;Model;Vehicle condition;Circumstance;Incident description;Missing/damaged cargo weight (kgs);Final invoice received;Payout date;Payout received;Amount rejected;Amount reduced;Outcome;Outcome for employees;Loading location;Unload location;Incident location;Latitude;Longitude;Followers;Duration;FIRDuration;Factual Carrier;Answer for claimant within 24-72 hours;Allow direct contact with claimant;Claiming party;Missing/damaged cargo amount;Required file categories;\n'

  csvContent += data
    .map((item) =>
      Object.values(item)
        .map((el) => {
          if (typeof el === 'string' && el.includes('#')) {
            return el.replaceAll('#', ' ')
          } else {
            return el
          }
        })
        .join(';')
    )
    .join('\n')
    .replace(/(^\[)|(\]$)/gm, '')

  const csvData = encodeURI(csvContent)
  return csvData
}
