import UtilityService from '../../services/UtilityService'
import i18n from '@/plugins/i18n'

export const namespaced = true
export const state = {
  stages: [],
  pipeline: null,
}
export const getters = {
  value: (state) => {
    return state.value
  },
  stageById: (state) => (id) => {
    return state.stages.find((s) => s.id == id)
  },
  stagesById: (state) => (id) => {
    return state.stages.filter((s) => s.previousStageIds.includes(id))
  },
  previousStagesById: (state, getters) => (id) => {
    function comparePosition(a, b) {
      if (a.position < b.position) {
        return -1
      } else if (a.position > b.position) {
        return 1
      }
      return 0
    }
    if (id != null) {
      // IS: return full list if it is CMR type (2) or MTPL type (1)
      if (state.pipeline === 2) {
        return state.stages.filter((s) => s.id != id).toSorted(comparePosition)
      } else if (
        state.pipeline === 1 ||
        state.pipeline === 5 ||
        state.pipeline === 9
      ) {
        return state.stages.filter((s) => s.id != id)
      }
      let stage = getters.stageById(id)
      if (!stage) {
        return []
      }
      const previousStageIds = stage.previousStageIds
      const previousStages = state.stages.filter((stage) =>
        previousStageIds?.includes(stage.id)
      )

      const indirectPreviousStages = []
      for (const prevStage of previousStages) {
        const subIndirectStages = getters.previousStagesById(prevStage.id)
        indirectPreviousStages.push(prevStage, ...subIndirectStages)
      }
      return indirectPreviousStages
    } else {
      return []
    }
  },
  nextStagesById: (state) => (id) => {
    function comparePosition(a, b) {
      if (a.position < b.position) {
        return -1
      } else if (a.position > b.position) {
        return 1
      }
      return 0
    }
    // IS: return full list if it is CMR type (2) or MTPL type (1)
    if (state.pipeline === 2) {
      return state.stages.filter((s) => s.id != id).toSorted(comparePosition)
    } else if (
      state.pipeline === 1 ||
      state.pipeline === 5 ||
      state.pipeline === 9
    ) {
      return state.stages.filter((s) => s.id != id)
    }
    let tempStages = Object.assign([], state.stages)
    let previousStageIds = [id]
    let nextStages = []
    if (id != null) {
      const getPreviousStageIds = () => {
        let stage = tempStages.find((s) =>
          s.previousStageIds.some((id) => previousStageIds.includes(id))
        )
        if (stage == undefined) {
          return
        } else {
          if (
            stage.previousStageIds.some((s) => previousStageIds.includes(s))
          ) {
            previousStageIds.push(stage.id)
            tempStages = tempStages.filter((s) => s.id != stage.id)
            getPreviousStageIds()
          }
        }
      }
      getPreviousStageIds()
      state.stages.forEach((stage) => {
        if (stage.previousStageIds.some((s) => previousStageIds.includes(s))) {
          nextStages.push(stage)
        }
      })
    }
    return nextStages
  },
  closedStages: (state) => {
    if (state.stages) {
      return state.stages.filter(
        (s) =>
          s.previousStageIds.length > 0 &&
          !state.stages
            .map((s) => s.previousStageIds)
            .flat()
            .includes(s.id)
      )
    } else {
      return []
    }
  },
  closedStagesDisplayNames: (state, getters) => {
    if (getters.closedStages.length > 0) {
      return getters.closedStages.map((s) => s.displayName)
    } else {
      return []
    }
  },
}
export const mutations = {
  SET_STAGES(state, stages) {
    state.stages = stages.map((s) => {
      return {
        ...s,
        displayName: i18n.t(`stages["${s.displayName}"]`),
      }
    })
  },
  SET_PIPELINE(state, pipelineId) {
    state.pipeline = pipelineId
  },
  CLEAR_STAGES(state) {
    state.stages = []
  },

  // ADD_DRIVER(state, driver) {
  //   state.drivers = [...state.drivers, driver]
  // },
}
export const actions = {
  // updateValue({ commit }, payload) {
  //   commit('updateValue', payload)
  // },
  async getStages({ commit }, pipelineId) {
    UtilityService.getStages(pipelineId).then((response) => {
      commit('SET_STAGES', response.data.stages)
      commit('SET_PIPELINE', pipelineId)
    })
  },
  // async createDriver({ commit }, driver) {
  //   return UtilityService.addDriver(driver).then((response) => {
  //     commit('ADD_DRIVER', response.data.driver)
  //     return response.data.driver.id
  //   })
  // },
  // async deleteDriver({ dispatch }, payload) {
  //   return UtilityService.deleteDriver(payload).then(() => {
  //     dispatch('getDrivers', payload.clientId)
  //   })
  // },
  // async updateDriver({ dispatch }, payload) {
  //   console.log(payload)
  //   return UtilityService.updateDriver(payload).then(() => {
  //     dispatch('getDrivers', payload.clientId)
  //   })
  // },
  async getAllStages({ commit }) {
    UtilityService.getAllStages().then((response) => {
      commit('SET_STAGES', response.data.stages)
    })
  },
  clearStages({ commit }) {
    commit('CLEAR_STAGES')
  },
}
