<template>
  <v-container>
    <LatestReminder
      v-if="claim && claim.latestReminder"
      :latestReminder="claim.latestReminder"
    />
    <v-row>
      <v-col>
        <v-stepper v-model="currentStep" vertical alt-labels>
          <v-dialog v-model="deleteDialog">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this file?</v-card-title
              >
              <v-card-text v-if="fileToDelete">
                {{ `${fileToDelete.name}.${fileToDelete.extension}` }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  outlined
                  @click="unlinkFile(fileToDelete)"
                  text
                  >OK</v-btn
                >
                <v-btn color="blue darken-1" @click="deleteDialog = false" text
                  >Cancel</v-btn
                >

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <Modal
            :show="showFileCategoryChangeModal"
            @toggle="toggleFileCategoryChangeModal"
            :title="$t('forms.changeFileCategoryForm')"
          >
            <FileCategoryChangeForm
              :fileCategories="fileCategories"
              :file="selectedFile"
              @toggle="toggleFileCategoryChangeModal"
            />
          </Modal>
          <Modal
            :show="showImageViewerModal"
            @toggle="toggleImageViewerModal"
            :title="$t('wizard.imageViewer')"
          >
            <ImageViewer
              :imageFileIndex="imageFileIndex"
              :imageFiles="imageFiles"
              :show="showImageViewerModal"
              @toggle="toggleImageViewerModal"
            />
          </Modal>

          <Modal
            :show="showClientModal"
            @toggle="toggleClientFormModal"
            title="Client form"
          >
            <ClientForm
              @saveClient="saveClient"
              @toggle="toggleClientFormModal"
            />
          </Modal>
          <v-stepper-header>
            <template v-for="step in steps">
              <v-stepper-step
                class="stepper__enlarge"
                :key="`${step.index}-step`"
                :complete="currentStep > step.index"
                :step="step.index"
                editable
              >
                {{ step.title }}
              </v-stepper-step>

              <v-divider
                v-if="step.index !== steps.length"
                :key="step.index"
              ></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items fill-height>
            <v-stepper-content step="1">
              <form>
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-account-circle"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.general') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-autocomplete
                      v-if="clients"
                      ref="clientId"
                      v-model="formData.clientId"
                      :items="selectClients"
                      :error-messages="clientErrors"
                      @change="selectClient"
                      value="formData.cliendId"
                      :label="$t('wizard.selectCompany') + '*'"
                      @blur="$v.formData.clientId.$touch()"
                      @input="$v.formData.clientId.$touch()"
                    >
                      <v-list-item
                        v-if="!isClient"
                        slot="prepend-item"
                        class="v-list-item v-list-item--link"
                        @click="toggleClientFormModal"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="orange--text">{{
                            $t('wizard.createNewClient')
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item></v-autocomplete
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-if="$route.params.id"
                      readonly
                      disabled
                      v-model="formData.id"
                      :label="$t('wizard.claimId')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      :readonly="
                        isClient ||
                        (!isAdmin &&
                          formData.claimNumber &&
                          formData.claimNumber != '')
                      "
                      v-model="formData.claimNumber"
                      :label="$t('wizard.claimNumber')"
                      prepend-icon="mdi-file-document-edit"
                    >
                      <v-btn
                        icon
                        color="primary"
                        dark
                        slot="append-outer"
                        @click="getClaimNumber"
                        :v-if="!isClient"
                        :disabled="claimNumberButton || isClient"
                      >
                        <v-icon color="orange darken-2"
                          >mdi-database-arrow-down</v-icon
                        >
                      </v-btn>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      dense
                      v-model="formData.otherClaimNumber"
                      :label="$t('wizard.otherClaimNumber')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      dense
                      type="number"
                      v-model="formData.amountReserve"
                      :label="$t('wizard.reserve')"
                      prefix="€"
                      data-vv-name="Amount Reserve"
                      data-vv-as="Amount Reserve"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      dense
                      type="number"
                      v-model="formData.amountReduced"
                      :label="$t('wizard.amountReduced')"
                      prefix="€"
                      data-vv-name="Amount Reduced"
                      data-vv-as="Amount Reduced"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      dense
                      type="number"
                      v-model="formData.amountRejected"
                      :label="$t('wizard.amountRejected')"
                      prefix="€"
                      data-vv-name="Amount Rejected"
                      data-vv-as="Amount Rejected"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" sm="12">
                    <v-textarea
                      dense
                      v-model="formData.outcome"
                      outlined
                      :label="$t('wizard.outcome')"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
              </form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <form data-vv-scope="form1">
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-account-circle"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.incidentDetails') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="12">
                    <v-textarea
                      v-model="formData.description"
                      outlined
                      :label="$t('wizard.incidentDescription')"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
              </form>
            </v-stepper-content>

            <v-stepper-content step="3">
              <form>
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-file-document"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.documents') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6">
                    <FileUpload
                      :label="$t('fileCategories.documents')"
                      category="Documents"
                      @handleUploadedFile="handleUploadedFile"
                    />
                  </v-col>
                </v-row>
                <v-card>
                  <v-toolbar color="#ea853c " dark>
                    <v-toolbar-title>{{
                      $t('wizard.attachments')
                    }}</v-toolbar-title>
                  </v-toolbar>
                  <v-row v-if="formData.attachmentIds.length > 0">
                    <v-col cols="12" class="d-flex justify-end">
                      <div>
                        <v-btn
                          outlined
                          color="green lighten-1"
                          @click="downloadAllClaimFiles"
                        >
                          <v-icon> mdi-cloud-download </v-icon>
                          {{ $t('wizard.downloadAll') }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-list
                    v-for="(fileCategory, index) in fileCategories"
                    :key="index"
                    subheader
                    two-line
                    dense
                  >
                    <v-divider :v-if="index > 0" inset></v-divider>
                    <v-subheader inset>{{
                      translateFileCategory(fileCategory)
                    }}</v-subheader>
                    <v-list-item
                      v-for="(file, index) in categoryFiles(fileCategory)"
                      :key="index"
                      dense
                    >
                      <v-list-item-avatar>
                        <v-icon class="orange lighten-2" dark>
                          {{ fileIcon(file) }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="file.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="attachmentUploadDate(file)"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          v-if="!isClient"
                          icon
                          @click="selectFileForCategoryChange(file)"
                        >
                          <v-icon color="orange lighten-4"
                            >mdi-file-move</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn
                          v-if="file.mime.includes('image')"
                          icon
                          @click="showImageViewer(file)"
                        >
                          <v-icon color="green lighten-1"
                            >mdi-image-outline</v-icon
                          >
                        </v-btn>
                        <v-btn
                          v-if="file.mime.includes('pdf')"
                          icon
                          @click="openPDF(file)"
                        >
                          <v-icon color="red lighten-1"
                            >mdi-file-pdf-box</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn @click="selectFileToDelete(file)" icon>
                          <v-icon color="red lighten-1">mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn icon @click="downloadFile(file)">
                          <v-icon color="green lighten-1"
                            >mdi-cloud-download</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </form>
              <!-- <ThumbnailGallery :thumbnails="thumbnails" /> -->
            </v-stepper-content>
            <StageManagement
              v-if="formData.stage"
              :stage="formData.stage"
              :latestReminder="claim.latestReminder"
              @changeStage="changeStage"
            />
            <div v-for="(vError, index) in validationErrors" :key="index">
              <span
                v-if="$v.formData[vError.validationField].$error"
                class="error--text"
                >{{ vError.label }} {{ $t('validations.fieldHasErrors') }}
                <sup
                  ><v-icon
                    small
                    color="red lighten-2"
                    @click="setFocus(vError.step, vError.reference)"
                    >mdi-arrow-top-right-thick</v-icon
                  ></sup
                >
              </span>
            </div>
            <v-container>
              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn
                    v-if="$route.params.id"
                    to="/otherdashboard"
                    class="ma-2"
                  >
                    <v-icon left> mdi-arrow-left </v-icon
                    >{{ $t('wizard.backToClaimList') }}</v-btn
                  >
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="$route.params.id"
                    :disabled="submitButtonDisabled"
                    class="ma-2"
                    color="success"
                    @click="submitFormData"
                    >{{ $t('wizard.saveChanges') }}</v-btn
                  >
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="
                      steps.length == currentStep && $route.params.id == null
                    "
                    :disabled="submitButtonDisabled"
                    color="primary"
                    @click="submitFormData"
                    class="ma-2"
                  >
                    {{ $t('wizard.submit') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row justify="space-between">
                <v-col cols="auto">
                  <v-btn
                    v-if="currentStep != 1"
                    color="text"
                    @click="prevStep(currentStep)"
                    class="ma-2"
                  >
                    {{ $t('wizard.previous') }}
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="steps.length > currentStep"
                    color="primary"
                    @click="nextStep(currentStep)"
                    class="ma-2"
                  >
                    {{ $t('wizard.next') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//TODO: validations for date, etc.
import { required } from 'vuelidate/lib/validators'
import { mapState, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import axios from 'axios'
import ClientForm from './ClientForm.vue'
import StageManagement from './StageManagement.vue'
import ImageViewer from './ImageViewer.vue'
import FileCategoryChangeForm from './FileCategoryChangeForm.vue'

import LatestReminder from '../components/LatestReminder.vue'

import Modal from './Modal.vue'
import FileUpload from './FileUpload'
// import ThumbnailGallery from './ThumbnailGallery.vue'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import { findFileCategoryTranslation } from '../store/helpers'
dayjs.extend(customParseFormat)

export default {
  components: {
    Modal,
    FileUpload,
    ClientForm,
    StageManagement,
    ImageViewer,
    LatestReminder,
    FileCategoryChangeForm,

    // ThumbnailGallery,
  },
  data: function () {
    return {
      fileToDelete: null,
      deleteDialog: false,
      selectClients: [],
      selectedRadio: null,
      claimNumberButton: false,
      imageFileIndex: '',
      imageFiles: [],
      markers: [],
      center: { lat: 55.4281113, lng: 24.1197816 },
      formData: {
        clientId: '',
        claimNumber: '',
        otherClaimNumber: '',
        amountReserve: 0,
        amountReduced: 0,
        amountRejected: 0,
        outcome: '',
        description: '',
        attachmentIds: [],
        currencyId: 1,
      },
      stages: [{}],
      submitButtonDisabled: false,
      currentStep: 1,
      selectedFile: null,
      showFileCategoryChangeModal: false,
      selectedPolicy: '',
      showClientModal: false,
      showImageViewerModal: false,
      showInsurancePolicyModal: false,
      showCoordinateFields: false,
      incident_datepicker_show: false,
      incident_timepicker_show: false,
      Accident_Date: '',
      Accident_Time: '',
      Accident_Location: '',
      Owner_Email: '',
      Owner_Tel: '',
      Owner_Address: '',
      Owner_Name: '',
      Policy_Number: '',
      Accident_Description: '',
      Owner_Code: '',
      Accident_Coordinates: '',
      selectedReverseGeocodeRadio: '',
    }
  },
  validations: {
    formData: {
      clientId: {
        required,
      },
    },
  },
  methods: {
    setFocus(stepId, ref) {
      this.currentStep = stepId
      this.$refs[ref].focus()
    },

    async getClaimNumber() {
      if (!this.isClient) {
        this.$store
          .dispatch('mtpl/getClaimNumber', 9)
          .then((response) => {
            this.formData.claimNumber = response.data.claimNumber
            this.claimNumberButton = true
          })
          .catch(() => {
            let snackbar = {
              text: this.$i18n.t('snackbar.couldNotRetrieveClaimNumber'),
              color: 'error',
            }
            this.$store.dispatch('snackbar/addSnackbar', snackbar, {
              root: true,
            })
          })
      }
    },

    error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`)
    },
    nextStep(stepIndex) {
      if (this.currentStep <= this.steps.length)
        this.currentStep = stepIndex + 1
    },
    prevStep(stepIndex) {
      if (this.currentStep > 1) this.currentStep = stepIndex - 1
    },
    toggleClientFormModal() {
      this.showClientModal = !this.showClientModal
    },
    toggleImageViewerModal() {
      this.imageFileIndex = null
      this.showImageViewerModal = !this.showImageViewerModal
    },
    showImageViewer(file) {
      this.imageFileIndex = this.imageFiles.findIndex((i) => i.id == file.id)
      this.showImageViewerModal = true
    },
    openPDF(file) {
      axios({
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/${file.id}/download`, // File URL Goes Here
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const pdfUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )
        window.open(pdfUrl, '_blank')
      })
    },

    toggleFileCategoryChangeModal() {
      this.showFileCategoryChangeModal = !this.showFileCategoryChangeModal
      if (this.showFileCategoryChangeModal === false) {
        // this.selectedFile = null
      }
    },
    selectFileForCategoryChange(file) {
      this.selectedFile = file
      this.toggleFileCategoryChangeModal()
    },
    submitFormData() {
      this.$v.formData.$touch()
      if (!this.$v.$invalid && !this.submitButtonDisabled) {
        this.submitButtonDisabled = true
        setTimeout(() => {
          this.submitButtonDisabled = false
        }, 5000)
        const dataToSubmit = Object.assign({}, this.formData)
        if (!this.isAdmin && this.formData.claimNumber === '') {
          delete dataToSubmit.claimNumber
        }
        dataToSubmit.amountReserve = parseFloat(
          (dataToSubmit.amountReserve * 100).toFixed()
        )

        dataToSubmit.amountReduced = parseFloat(
          (dataToSubmit.amountReduced * 100).toFixed()
        )

        dataToSubmit.amountRejected = parseFloat(
          (dataToSubmit.amountRejected * 100).toFixed()
        )

        delete dataToSubmit.followers
        delete dataToSubmit.stageChangeDate
        if (typeof dataToSubmit.incidentLatitude == 'string') {
          dataToSubmit.incidentLatitude = parseFloat(
            dataToSubmit.incidentLatitude
          )
        }
        if (typeof dataToSubmit.incidentLongitude == 'string') {
          dataToSubmit.incidentLongitude = parseFloat(
            dataToSubmit.incidentLongitude
          )
        }

        if (this.$route.path != '/OTHERWizard') {
          this.$store.dispatch('mtpl/updateClaim', dataToSubmit)
        } else {
          dataToSubmit.reportedDate = new Date()
          delete dataToSubmit.status

          dataToSubmit.stageId = 96
          this.$store.dispatch('mtpl/createOTHERClaim', dataToSubmit)
        }
        this.$store.dispatch('site/preventNavigation', false)
      }
    },
    selectClient(value) {
      this.formData.clientId = value
    },

    handleUploadedFile(fileId) {
      this.formData.attachmentIds.push(fileId)
      this.$store.dispatch('file/getFile', fileId)
      this.$store.dispatch('site/preventNavigation', this.unsavedFileMessage)
    },

    saveClient(formData) {
      this.$store.dispatch('client/createClient', formData).then((clientId) => {
        this.selectClient(clientId)
      })
      this.toggleClientFormModal()
    },

    saveInsurancePolicy(values) {
      this.vehicles.push({
        text: values,
        value: values,
      })
      this.selectedPolicy = values
      this.insurancePolicyModal()
    },
    insurancePolicyModal() {
      this.showInsurancePolicyModal = !this.showInsurancePolicyModal
    },
    toggleInsurancePolicyModal() {
      this.showInsurancePolicyModal = !this.showInsurancePolicyModal
    },
    vehicleRegistrationChange(files) {
      this.form
      if (files.length > 0)
        files.map((f) => {
          f.category = 'vehicleRegistration'
          return f
        })
      this.formData.attachmentIds.push(files)
      console.table(this.formData.attachments)
    },
    categoryFiles(category) {
      return this.files.filter((f) => f.category == category)
    },
    fileIcon(file) {
      if (file.mime.includes('image')) {
        return 'mdi-image-outline'
      } else {
        return 'mdi-clipboard-text'
      }
    },
    attachmentUploadDate(file) {
      return dayjs(file.createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    downloadFile(file) {
      axios({
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/${file.id}/download`, // File URL Goes Here
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data], { type: file.mime })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${file.name}.${file.extension}`
        link.click()
      })
    },
    downloadAllClaimFiles() {
      axios({
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/claims/${this.formData.id}/download`,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `claim${
          this.formData.claimNumber
            ? this.formData.claimNumber
            : this.formData.id
        }files.zip`
        link.click()
      })
    },
    //TODO: unlinking should remove file from S3?
    unlinkFile(file) {
      this.formData.attachmentIds.filter((a) => a != file.id)
      if (this.$route.params.id) {
        this.formData.attachmentIds = this.formData.attachmentIds.filter(
          (f) => f != file.id
        )
        this.submitFormData()
      }
      this.deleteDialog = false
      this.$store.dispatch('file/removeFile', file)
    },
    selectFileToDelete(file) {
      this.fileToDelete = file
      this.deleteDialog = true
    },
    changeStage(stageId) {
      if (stageId != null) {
        this.$store
          .dispatch('mtpl/changeStage', {
            stageId,
            claimId: this.$route.params.id,
          })
          .then((response) => {
            let { stage } = response.data.claim
            this.$nextTick(() => {
              this.formData.stage = stage
              this.formData.stageId = stage.id
            })
          })
      }
    },
    translateFileCategory(text) {
      return findFileCategoryTranslation(text)
    },
  },
  computed: {
    ...mapState({
      claim: (state) => state.mtpl.claim,
      clients: (state) => state.client.clients,
      files: (state) => state.file.files,
    }),
    ...mapGetters({
      stagesById: 'stage/stagesById',
      stageById: 'stage/stageById',
      role: 'user/role',
    }),
    fileCategories() {
      let fileCategoryArray = []
      fileCategoryArray.push('Documents')
      return fileCategoryArray
    },

    steps() {
      return [
        {
          index: 1,
          title: this.$i18n.t('wizard.steps.general'),
        },
        {
          index: 2,
          title: this.$i18n.t('wizard.steps.details'),
        },
        {
          index: 3,
          title: this.$i18n.t('wizard.steps.documents'),
        },
      ]
    },

    validationErrors() {
      return [
        {
          validationField: 'clientId',
          label: this.$i18n.t('wizard.client'),
          step: 1,
          reference: 'clientId',
        },
      ]
    },
    timeRule() {
      return [
        (value) =>
          /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value) ||
          this.$i18n.t('wizard.timeFormatValidation'),
      ]
    },
    dateRule() {
      return [
        (value) =>
          /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(value) ||
          this.$i18n.t('wizard.dateFormatValidation'),
      ]
    },
    created() {
      return this.$route.path != '/OTHERWizard'
    },
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    isAdmin() {
      return this.$store.getters['user/role'] == 'admin'
    },

    unsavedFileMessage() {
      return this.$i18n.t('dialogMessages.unsavedFile')
    },

    // TODO: change this method to track when ICN Stages should trigger required state for a claim.
    checkICNStages() {
      // let requiredStages = [
      //   'Agreed, but unpaid',
      //   'Missing contact details',
      //   'Large claim',
      //   'Reported by insurer',
      //   'Negotiation failed',
      //   'Registered with insurer',
      // ]
      if (this.claim?.stage != null) {
        // return requiredStages.includes(this.claim.stage.displayName)
        return true
      } else {
        return false
      }
    },
    clientErrors() {
      const errors = []
      if (!this.$v.formData.clientId.$dirty) return errors
      !this.$v.formData.clientId.required &&
        errors.push(this.$i18n.t('validations.clientRequired'))
      return errors
    },
  },
  watch: {
    claim() {
      this.formData = Object.assign({}, this.claim)

      if (isNaN(this.formData.amountReserve)) {
        this.formData.amountReserve = 0
      }
      this.formData.amountReserve = (this.formData.amountReserve / 100).toFixed(
        2
      )

      if (isNaN(this.formData.amountReduced)) {
        this.formData.amountReduced = 0
      }
      this.formData.amountReduced = (this.formData.amountReduced / 100).toFixed(
        2
      )

      if (isNaN(this.formData.amountRejected)) {
        this.formData.amountRejected = 0
      }

      this.formData.amountRejected = (
        this.formData.amountRejected / 100
      ).toFixed(2)

      if (this.formData.attachmentIds.length > 0) {
        this.$store.dispatch('file/getFiles', this.formData.attachmentIds)
      }
    },
    clients() {
      this.selectClients = this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
    files() {
      this.$nextTick(() => {
        this.imageFiles = this.files.filter((f) => f.mime.includes('image'))
      })
    },
  },
  mounted() {
    this.$store.dispatch('client/getClients')
    if (this.$route.params.id && !this.isClient) {
      this.$store.dispatch('stage/getStages', 9)
    }
  },
  unmounted() {
    this.$store.dispatch('file/clearFiles')
  },
  destroyed() {
    this.$store.dispatch('file/clearFiles')
  },
}
</script>

<style lang="scss" scoped>
.vue-map-container {
  display: block;
  margin: auto;
}
.stepper__enlarge {
  flex-basis: 250px;
}
</style>
