import Vue from 'vue'
import VueRouter from 'vue-router'
import Insight from './../views/Insight.vue'
import store from './../store/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/calendar',
    name: 'Calendar',
    meta: { requiresAuth: true },
    component: () => import('../views/Calendar.vue'),
  },
  // {
  //   path: '/dashboard',
  //   component: () => import('../views/Dashboard.vue'),
  //   meta: { requiresAuth: true },
  //   children: [
  //     {
  //       path: '',
  //       name: 'Insight',
  //       component: Insight,
  //     },
  //     {
  //       path: 'data',
  //       component: () => import('./../components/MTPLDataTable.vue'),
  //     },
  //     {
  //       path: 'import',
  //       component: () => import('./../components/MTPLImport.vue'),
  //     },
  //   ],
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: () => import('../views/Dashboard.vue'),
  //   meta: { requiresAuth: true },
  //   children: [
  //     {
  //       path: '',
  //       name: 'Insight',
  //       component: Insight,
  //     },
  //     {
  //       path: 'data',
  //       component: () => import('./../components/MTPLDataTable.vue'),
  //     },
  //     {
  //       path: 'import',
  //       component: () => import('./../components/MTPLImport.vue'),
  //     },
  //   ],
  // },
  {
    path: '/mtpldashboard',
    component: () => import('../views/MTPLDashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'MTPL Dashboard',
        component: () => import('./../components/MTPLDataTable.vue'),
      },
      {
        path: 'insight',
        name: 'Insight',
        component: Insight,
      },
      {
        path: 'import',
        component: () => import('./../components/MTPLImport.vue'),
      },
    ],
  },
  {
    path: '/cmrdashboard',
    component: () => import('../views/CMRDashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'CMR Dashboard',
        component: () => import('./../components/CMRDataTable.vue'),
      },
    ],
  },
  {
    path: '/cidashboard',
    component: () => import('../views/CIDashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'CI Dashboard',
        component: () => import('./../components/CIDataTable.vue'),
      },
    ],
  },
  {
    path: '/cascodashboard',
    component: () => import('../views/CASCODashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'CASCO Dashboard',
        component: () => import('./../components/CASCODataTable.vue'),
      },
    ],
  },
  {
    path: '/mtplinjureddashboard',
    component: () => import('../views/MTPLInjuredDashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'MTPL Injured Dashboard',
        component: () => import('./../components/MTPLInjuredDataTable.vue'),
      },
    ],
  },
  {
    path: '/krdnadashboard',
    component: () => import('../views/KRDNADashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'KRD/NA Dashboard',
        component: () => import('./../components/KRDNADataTable.vue'),
      },
    ],
  },
  {
    path: '/cpdashboard',
    component: () => import('../views/CPDashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'CP Dashboard',
        component: () => import('./../components/CPDataTable.vue'),
      },
    ],
  },
  {
    path: '/bcadashboard',
    component: () => import('../views/BCADashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'BCA Dashboard',
        component: () => import('./../components/BCADataTable.vue'),
      },
    ],
  },
  {
    path: '/otherdashboard',
    component: () => import('../views/OTHERDashboard.vue'),

    beforeEnter: clientAccessDenied,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'OTHER Dashboard',
        beforeEnter: clientAccessDenied,
        component: () => import('./../components/OTHERDataTable.vue'),
      },
    ],
  },
  {
    path: '/mtpl/:id',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./../views/MTPLView'),
    children: [
      {
        path: '',
        name: 'MTPLShow',
        component: () => import('./../components/MTPLShow'),
      },
      {
        path: 'messages',
        name: 'MTPL Messages',
        component: () => import('./../components/Messages'),
      },
    ],
  },
  {
    path: '/cmr/:id',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./../views/CMRView'),
    children: [
      {
        path: '',
        name: 'CMRShow',
        component: () => import('./../components/CMRShow'),
      },
      {
        path: 'messages',
        name: 'CMR Messages',
        component: () => import('./../components/Messages'),
      },
    ],
  },
  {
    path: '/ci/:id',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./../views/CIView'),
    children: [
      {
        path: '',
        name: 'CIShow',
        component: () => import('./../components/CIShow'),
      },
      {
        path: 'messages',
        name: 'CI Messages',
        component: () => import('./../components/Messages'),
      },
    ],
  },
  {
    path: '/casco/:id',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./../views/CASCOView'),
    children: [
      {
        path: '',
        name: 'CASCOShow',
        component: () => import('./../components/CASCOShow'),
      },
      {
        path: 'messages',
        name: 'CASCO Messages',
        component: () => import('./../components/Messages'),
      },
    ],
  },
  {
    path: '/mtplinjured/:id',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./../views/MTPLInjuredView'),
    children: [
      {
        path: '',
        name: 'MTPLInjuredShow',
        component: () => import('./../components/MTPLInjuredShow'),
      },
      {
        path: 'messages',
        name: 'MTPL Injured Messages',
        component: () => import('./../components/Messages'),
      },
    ],
  },
  {
    path: '/krdna/:id',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./../views/KRDNAView'),
    children: [
      {
        path: '',
        name: 'KRDNAShow',
        component: () => import('./../components/KRDNAShow'),
      },
      {
        path: 'messages',
        name: 'KRD/NA Messages',
        component: () => import('./../components/Messages'),
      },
    ],
  },
  {
    path: '/cp/:id',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./../views/CPView'),
    children: [
      {
        path: '',
        name: 'CPShow',
        component: () => import('./../components/CPShow'),
      },
      {
        path: 'messages',
        name: 'CP Messages',
        component: () => import('./../components/Messages'),
      },
    ],
  },
  {
    path: '/bca/:id',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./../views/BCAView'),
    children: [
      {
        path: '',
        name: 'BCAShow',
        component: () => import('./../components/BCAShow'),
      },
      {
        path: 'messages',
        name: 'BCA Messages',
        component: () => import('./../components/Messages'),
      },
    ],
  },
  {
    path: '/other/:id',
    props: true,
    beforeEnter: clientAccessDenied,
    meta: { requiresAuth: true },
    component: () => import('./../views/OTHERView'),
    children: [
      {
        path: '',
        name: 'OTHERShow',
        beforeEnter: clientAccessDenied,
        component: () => import('./../components/OTHERShow'),
      },
      {
        path: 'messages',
        name: 'OTHER Messages',
        beforeEnter: clientAccessDenied,
        component: () => import('./../components/Messages'),
      },
    ],
  },
  {
    path: '/user',
    component: () => import('./../views/User'),
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Profile',
        component: () => import('./../components/UserProfile'),
        meta: { requiresAuth: true },
      },
      {
        path: 'registration',
        name: 'Registration',
        beforeEnter: clientAccessDenied,
        component: () => import('./../components/UserRegistration'),
        meta: { requiresAuth: true },
      },
      {
        path: 'userlist',
        name: 'User List',
        beforeEnter: clientAccessDenied,
        component: () => import('./../components/UserList'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/MTPLWizard',
    name: 'MTPLWizard',
    component: () => import('../components/MTPLWizard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/CMRWizard',
    name: 'CMRWizard',
    component: () => import('../components/CMRWizard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/CIWizard',
    name: 'CIWizard',
    component: () => import('../components/CIWizard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/CASCOWizard',
    name: 'CASCOWizard',
    component: () => import('../components/CASCOWizard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/MTPLInjuredWizard',
    name: 'MTPLInjuredWizard',
    component: () => import('../components/MTPLInjuredWizard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/KRDNAWizard',
    name: 'KRDNAWizard',
    component: () => import('../components/KRDNAWizard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/CPWizard',
    name: 'CPWizard',
    component: () => import('../components/CPWizard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/BCAWizard',
    name: 'BCAWizard',
    component: () => import('../components/BCAWizard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/OTHERWizard',
    name: 'OTHERWizard',
    beforeEnter: clientAccessDenied,
    component: () => import('../components/OTHERWizard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/policies',
    name: 'Policies',
    component: () => import('../views/Policies.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tests',
    name: 'TestWrapper',
    component: () => import('../components/TestWrapper.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/imports',
    name: 'ImportMTPL',
    component: () => import('../components/MTPLImport.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/mtpldata',
    name: 'MTPLDataTable',
    component: () => import('../components/MTPLDataTable.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reset/:code',
    name: 'ResetPassword',
    component: () => import('../views/PasswordChangeForm.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/vehicle',
    component: () => import('./../views/Vehicle'),
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'VehicleManagement',
        component: () => import('../components/VehicleManagement.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'import',
        name: 'VehicleImport',
        beforeEnter: clientAccessDenied,
        component: () => import('./../components/VehicleImport'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/driver',
    component: () => import('./../views/Driver'),
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'DriverManagement',
        component: () => import('../components/DriverManagement.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'import',
        name: 'DriverImport',
        beforeEnter: clientAccessDenied,
        component: () => import('./../components/DriverImport'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/insuredperson',
    component: () => import('./../views/InsuredPerson'),
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'InsuredPersonManagement',
        component: () => import('../components/InsuredPersonManagement.vue'),
        meta: { requiresAuth: true },
      },
      // {
      //   path: 'import',
      //   name: 'InsuredPersonImport',
      //   beforeEnter: clientAccessDenied,
      //   component: () => import('./../components/VehicleImport'),
      //   meta: { requiresAuth: true },
      // },
    ],
  },
  {
    path: '/join/:code',
    name: 'SetPassword',
    component: () => import('../views/PasswordChangeForm.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/activitylog',
    name: 'ActivityLog',
    beforeEnter: adminRequired,
    component: () => import('../views/ActivityLog.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/client/:id?',
    name: 'ClientView',
    component: () => import('../views/ClientView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: () => import('../views/CookiePolicy.vue'),
  },
  {
    path: '/cookie-status',
    name: 'CookieStatus',
    component: () => import('../views/CookieStatus.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  const cookiesAccepted = localStorage.getItem('cookie:accepted')
  const loggedIn = localStorage.getItem('user')
  const pv = store.state.site.preventNavigation
  if (pv.prevent) {
    const leave = window.confirm(pv.text) // eslint-disable-line no-alert
    if (!leave) return
  }
  store.dispatch('site/preventNavigation')
  if (cookiesAccepted == null || cookiesAccepted == 'true ') {
    next()
  }
  if (cookiesAccepted == 'false') {
    if (loggedIn) {
      store.dispatch('user/logout')
    } else if (to.name != 'CookieStatus') {
      next('/cookie-status')
    }
  }
  next()
})

function adminRequired(to, from, next) {
  if (store.getters['user/role'] == 'admin') {
    next()
  } else {
    next('/')
  }
}

function clientAccessDenied(to, from, next) {
  if (store.getters['user/role'] != 'client') {
    next()
  } else {
    next('/')
  }
}

//TODO: pipelines restriction based on user pipelines

export default router
