<template>
  <v-container>
    <v-dialog v-model="dialogVehicleDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('dialogMessages.vehicleDeleteThisVehicle')
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="toggleVehicleRemoveDialog"
            >{{ $t('buttons.cancel') }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteVehicleConfirm">{{
            $t('buttons.ok')
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LatestReminder
      v-if="claim && claim.latestReminder"
      :latestReminder="claim.latestReminder"
    />
    <v-row>
      <v-col>
        <v-stepper v-model="currentStep" vertical alt-labels>
          <v-dialog v-model="deleteDialog">
            <v-card>
              <v-card-title class="text-h5">{{
                $t('dialogMessages.fileDeleteConfirmation')
              }}</v-card-title>
              <v-card-text v-if="fileToDelete">
                {{ `${fileToDelete.name}.${fileToDelete.extension}` }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  outlined
                  @click="unlinkFile(fileToDelete)"
                  text
                  >{{ $t('buttons.ok') }}</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  @click="deleteDialog = false"
                  text
                  >{{ $t('buttons.cancel') }}</v-btn
                >

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <Modal
            :show="showFileCategoryChangeModal"
            @toggle="toggleFileCategoryChangeModal"
            :title="$t('forms.changeFileCategoryForm')"
          >
            <FileCategoryChangeForm
              :fileCategories="fileCategories"
              :file="selectedFile"
              @toggle="toggleFileCategoryChangeModal"
            />
          </Modal>
          <Modal
            :show="showInsuredVehicleModal"
            @toggle="toggleInsuredVehicleModal"
            :title="$t('wizard.insuredVehicleForm')"
          >
            <VehicleForm
              @saveVehicle="saveInsuredVehicle"
              @toggle="toggleInsuredVehicleModal"
            />
          </Modal>

          <Modal
            :show="showImageViewerModal"
            @toggle="toggleImageViewerModal"
            :title="$t('wizard.imageViewer')"
          >
            <ImageViewer
              :imageFileIndex="imageFileIndex"
              :imageFiles="imageFiles"
              :show="showImageViewerModal"
              @toggle="toggleImageViewerModal"
            />
          </Modal>

          <Modal
            :show="showDriverModal"
            @toggle="toggleDriverModal"
            :title="$t('wizard.driverForm')"
          >
            <DriverForm @saveDriver="saveDriver" @toggle="toggleDriverModal" />
          </Modal>

          <Modal
            :show="showInsurancePolicyModal"
            @toggle="toggleInsurancePolicyModal"
            title="Insurance policy"
          >
            <InsurancePolicyForm
              @savePolicy="saveInsurancePolicy"
              @toggle="toggleInsurancePolicyModal"
            />
          </Modal>

          <Modal
            :show="showClientModal"
            @toggle="toggleClientFormModal"
            title="Client form"
          >
            <ClientForm
              @saveClient="saveClient"
              @toggle="toggleClientFormModal"
            />
          </Modal>
          <v-stepper-header>
            <template v-for="step in steps">
              <v-stepper-step
                class="stepper__enlarge"
                :key="`${step.index}-step`"
                :complete="currentStep > step.index"
                :step="step.index"
                editable
              >
                {{ step.title }}
              </v-stepper-step>

              <v-divider
                v-if="step.index !== steps.length"
                :key="step.index"
              ></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items fill-height>
            <v-stepper-content step="1">
              <form>
                <v-container v-if="!isClient">
                  <v-row class="d-flex justify-start">
                    <v-col cols="3">
                      <v-btn
                        v-if="!isClient"
                        @click="setColour"
                        :color="claim?.colour ? 'warning' : 'success'"
                      >
                        <v-icon>mdi-star</v-icon>
                        {{ colourButtonTitle }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-account-circle"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.general') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" sm="3">
                    <BaseCheckbox
                      dense
                      v-model="formData.isCargoClaim"
                      :label="$t('wizard.cargoClaim')"
                    ></BaseCheckbox>
                    <BaseCheckbox
                      dense
                      v-model="formData.isClaimToThirdParty"
                      :label="$t('wizard.claimToThirdParty')"
                    ></BaseCheckbox>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <BaseCheckbox
                      dense
                      v-model="formData.isClaimToEntrustedProperty"
                      :label="$t('wizard.claimToEntrustedProperty')"
                    ></BaseCheckbox>
                    <BaseCheckbox
                      dense
                      v-model="formData.isFreightForwardingClaim"
                      :label="$t('wizard.freightForwardingClaim')"
                    ></BaseCheckbox>
                  </v-col>
                  <v-col cols="3" sm="3"
                    ><BaseCheckbox
                      dense
                      v-model="formData.isWarehouseKeepersLiability"
                      :label="$t('wizard.warehouseKeepersLiability')"
                    ></BaseCheckbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-autocomplete
                      v-if="clients"
                      ref="clientId"
                      v-model="formData.clientId"
                      :items="selectClients"
                      :error-messages="clientErrors"
                      @change="selectClient"
                      value="formData.cliendId"
                      :label="$t('wizard.selectCompany') + '*'"
                      @blur="$v.formData.clientId.$touch()"
                      @input="$v.formData.clientId.$touch()"
                    >
                      <v-list-item
                        v-if="!isClient"
                        slot="prepend-item"
                        class="v-list-item v-list-item--link"
                        @click="toggleClientFormModal"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="orange--text">{{
                            $t('wizard.createNewClient')
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item></v-autocomplete
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      dense
                      v-model="formData.clientClaimNumber"
                      :label="$t('wizard.clientClaimNumber')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="$route.params.id">
                  <v-col cols="6" sm="3">
                    <v-text-field
                      readonly
                      disabled
                      v-model="formData.id"
                      :label="$t('wizard.claimId')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="$route.params.id">
                  <!-- <v-col cols="6" sm="3">
                    <v-text-field v-model="formData.vin" label="VIN">
                    </v-text-field>
                  </v-col> -->
                  <v-col cols="6" sm="3">
                    <v-text-field
                      :readonly="
                        isClient ||
                        (!isAdmin &&
                          formData.claimNumber &&
                          formData.claimNumber != '')
                      "
                      v-model="formData.claimNumber"
                      :label="$t('wizard.claimNumber')"
                      prepend-icon="mdi-file-document-edit"
                    >
                      <v-btn
                        icon
                        color="primary"
                        dark
                        slot="append-outer"
                        @click="getClaimNumber"
                        :v-if="!isClient"
                        :disabled="claimNumberButton || isClient"
                      >
                        <v-icon color="orange darken-2"
                          >mdi-database-arrow-down</v-icon
                        >
                      </v-btn>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="$route.params.id">
                  <v-col cols="6" sm="3">
                    <v-select
                      dense
                      :readonly="isClient"
                      v-model="formData.insuranceProvider"
                      :label="$t('wizard.insuranceProvider')"
                      :items="insuranceProviderList"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      dense
                      :readonly="isClient"
                      v-model="formData.insuranceClaimNumber"
                      :label="$t('wizard.insuranceClaimNumber')"
                      prepend-icon="mdi-shield-car"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3" v-if="!isClient">
                    <BaseCheckbox
                      dense
                      v-model="formData.registerInInsurance"
                      :label="$t('wizard.registerInInsurance')"
                    ></BaseCheckbox>
                  </v-col>
                </v-row>
                <v-row v-if="!isOnlyWarehouseKeepersLiability">
                  <v-col cols="6" sm="3">
                    <v-text-field
                      dense
                      v-model="formData.factualCarrier"
                      :label="$t('wizard.factualCarrier')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!isOnlyWarehouseKeepersLiability">
                  <v-col>
                    <BaseCheckbox
                      v-model="formData.isUrgent"
                      :label="$t('wizard.isUrgent')"
                    ></BaseCheckbox>
                  </v-col>
                </v-row>
                <v-row v-if="!isOnlyWarehouseKeepersLiability">
                  <v-col cols="6" sm="3">
                    <BaseCheckbox
                      dense
                      v-model="formData.allowDirectContact"
                      :label="$t('wizard.allowDirectContactWithClaimant')"
                    ></BaseCheckbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    sm="3"
                    v-if="!isOnlyWarehouseKeepersLiability"
                  >
                    <v-text-field
                      v-model="formData.claimingParty"
                      :label="$t('wizard.claimingParty')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-menu
                      v-if="$route.params.id"
                      v-model="dateDeclaredForInsurer_datepicker_show"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :readonly="isClient"
                          v-model="formData.dateDeclaredForInsurer"
                          :label="$t('wizard.declaredForInsurerDate')"
                          v-on="isClient ? '' : on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.dateDeclaredForInsurer"
                        @input="dateDeclaredForInsurer_datepicker_show = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                    sm="3"
                    v-if="!isOnlyWarehouseKeepersLiability"
                  >
                    <v-menu
                      v-model="unloadingDate_datepicker_show"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="unloadingDate"
                          v-model="formData.unloadingDate"
                          :label="$t('wizard.unloadingDate') + '*'"
                          v-on="on"
                          :error-messages="unloadingDateErrors"
                          @input="$v.formData.unloadingDate.$touch()"
                          @blur="$v.formData.unloadingDate.$touch()"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.unloadingDate"
                        @input="unloadingDate_datepicker_show = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-menu
                      v-model="incident_datepicker_show"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="incidentDate"
                          v-model="formData.incidentDate"
                          :label="
                            isOnlyWarehouseKeepersLiability
                              ? $t('wizard.incidentDate') + '*'
                              : $t('wizard.incidentDate')
                          "
                          v-on="on"
                          :error-messages="incidentDateErrors"
                          @input="$v.formData.incidentDate.$touch()"
                          @blur="$v.formData.incidentDate.$touch()"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.incidentDate"
                        @input="incident_datepicker_show = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row v-if="!isOnlyWarehouseKeepersLiability">
                  <v-col cols="6" sm="3">
                    <v-autocomplete
                      dense
                      ref="vehicleId"
                      v-model="formData.vehicleId"
                      :label="$t('wizard.insuredVehicle') + '*'"
                      :error-messages="vehicleErrors"
                      :items="selectVehicles"
                      :menu-props="{ maxHeight: 125 }"
                      @change="selectVehicle"
                      return-object
                      @blur="$v.formData.vehicleId.$touch()"
                      @input="$v.formData.vehicleId.$touch()"
                    >
                      <v-list-item
                        slot="prepend-item"
                        class="v-list-item v-list-item--link"
                        @click="toggleInsuredVehicleModal"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="orange--text">{{
                            $t('wizard.createNewVehicle')
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-autocomplete
                      dense
                      :label="$t('wizard.driver') + '*'"
                      ref="driverId"
                      v-model="formData.driverId"
                      :items="selectDrivers"
                      :error-messages="driverErrors"
                      :menu-props="{ maxHeight: 125 }"
                      @change="selectDriver"
                      return-object
                      @blur="$v.formData.driverId.$touch()"
                      @input="$v.formData.driverId.$touch()"
                    >
                      <v-list-item
                        slot="prepend-item"
                        class="v-list-item v-list-item--link"
                        @click="toggleDriverModal"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="orange--text">{{
                            $t('wizard.createNewDriver')
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="!isOnlyWarehouseKeepersLiability">
                  <v-col cols="6" sm="3">
                    <v-menu
                      v-model="finalInvoiceReceivedDate_datepicker_show"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          clearable
                          v-model="formData.finalInvoiceReceivedDate"
                          :label="$t('wizard.finalInvoiceReceived')"
                          v-on="on"
                          dense
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.finalInvoiceReceivedDate"
                        @input="
                          finalInvoiceReceivedDate_datepicker_show = false
                        "
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row dense v-if="!isOnlyWarehouseKeepersLiability">
                  <v-col cols="6" sm="3">
                    <v-menu
                      v-if="$route.params.id"
                      v-model="reported_datepicker_show"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-if="$route.params.id"
                          :readonly="isClient"
                          v-model="formData.reportedDate"
                          :label="$t('wizard.reportedDate')"
                          v-on="isClient ? '' : on"
                          dense
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.reportedDate"
                        @input="reported_datepicker_show = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      dense
                      type="number"
                      v-model="formData.amountReserve"
                      :label="$t('wizard.reserve')"
                      prefix="€"
                      data-vv-name="Amount Reserve"
                      data-vv-as="Amount Reserve"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <BaseCheckbox
                      v-if="!isClient"
                      dense
                      v-model="formData.reserveReceived"
                      :label="$t('wizard.reserveReceived')"
                      :readonly="isClient"
                    ></BaseCheckbox>
                  </v-col>
                </v-row>
                <v-row
                  dense
                  align="center"
                  v-if="!isOnlyWarehouseKeepersLiability"
                >
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-if="$route.params.id"
                      dense
                      type="number"
                      v-model="formData.amountClaimed"
                      :readonly="isClient"
                      :label="$t('wizard.amountClaimed')"
                      prefix="€"
                      data-vv-name="Amount Claimed"
                      data-vv-as="Amount Claimed"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-if="$route.params.id"
                      :readonly="isClient"
                      dense
                      type="number"
                      v-model="formData.payoutReceived"
                      :label="$t('wizard.payoutReceived')"
                      prefix="€"
                      data-vv-name="Payout Received"
                      data-vv-as="Payout Received"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-menu
                      v-model="payoutDate_datepicker_show"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-if="$route.params.id"
                          :readonly="isClient"
                          v-model="formData.payoutDate"
                          :label="$t('wizard.payoutDate')"
                          v-on="on"
                          dense
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.payoutDate"
                        @input="payoutDate_datepicker_show = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row v-if="!isOnlyWarehouseKeepersLiability">
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-if="$route.params.id"
                      :readonly="isClient"
                      dense
                      type="number"
                      v-model="formData.amountRejected"
                      :label="$t('wizard.amountRejected')"
                      prefix="€"
                      data-vv-name="Amount Rejected"
                      data-vv-as="Amount Rejected"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-if="$route.params.id"
                      :readonly="isClient"
                      dense
                      type="number"
                      v-model="formData.amountReduced"
                      :label="$t('wizard.amountReduced')"
                      prefix="€"
                      data-vv-name="Amount Reduced"
                      data-vv-as="Amount Reduced"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row> </v-row>

                <v-row>
                  <v-col cols="6" sm="12">
                    <v-textarea
                      dense
                      v-if="$route.params.id"
                      :readonly="isClient"
                      v-model="formData.outcome"
                      outlined
                      :label="$t('wizard.outcome')"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="12">
                    <v-textarea
                      dense
                      v-if="$route.params.id && !isClient"
                      :readonly="isClient"
                      v-model="formData.outcomeForEmployees"
                      outlined
                      :label="$t('wizard.outcomeForEmployees')"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
              </form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <form data-vv-scope="form1">
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-map-marker"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.location') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col>
                    <v-text-field
                      ref="loadingLocation"
                      v-model="formData.loadingLocation"
                      :error-messages="loadingLocationErrors"
                      prepend-icon="mdi-package-up"
                      :label="$t('wizard.loadingLocation') + '*'"
                      placeholder="UADBB DRAUDIMO BITĖS, Perkūnkiemio str. 3, LT-12127 Vilnius"
                      data-vv-name="Loading_Location"
                      data-vv-scope="form1"
                      data-vv-as="Loading Location"
                      @input="$v.formData.loadingLocation.$touch()"
                      @blur="$v.formData.loadingLocation.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row> -->
                <v-row v-if="!isOnlyWarehouseKeepersLiability">
                  <v-col>
                    <v-combobox
                      :disabled="formData.clientId == ''"
                      ref="loadingLocation"
                      v-model="formData.loadingLocation"
                      :error-messages="loadingLocationErrors"
                      prepend-icon="mdi-package-down"
                      :loading="isLoadingLocationLoading"
                      :items="suggestedLoadingLocationItems"
                      :label="$t('wizard.loadingLocation') + '*'"
                      :cache-items="false"
                      hide-no-data
                      :search-input.sync="searchLoadingLocation"
                      data-vv-name="LoadingLocation"
                      data-vv-scope="form1"
                      data-vv-as="Loading Location"
                      @input="$v.formData.loadingLocation.$touch()"
                      @blur="$v.formData.loadingLocation.$touch()"
                    ></v-combobox>
                  </v-col>
                </v-row>

                <v-row v-if="!isOnlyWarehouseKeepersLiability">
                  <v-col>
                    <v-combobox
                      :disabled="formData.clientId == ''"
                      ref="unloadLocation"
                      v-model="formData.unloadLocation"
                      :error-messages="unloadLocationErrors"
                      prepend-icon="mdi-package-down"
                      :loading="isUnloadLocationLoading"
                      :items="suggestedUnloadLocationItems"
                      :label="$t('wizard.unloadLocation') + '*'"
                      :cache-items="false"
                      hide-no-data
                      :search-input.sync="searchUnloadLocation"
                      data-vv-name="UnloadLocation"
                      data-vv-scope="form1"
                      data-vv-as="Unload Location"
                      @input="$v.formData.unloadLocation.$touch()"
                      @blur="$v.formData.unloadLocation.$touch()"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-col>
                    <v-text-field
                      ref="searchText"
                      prepend-icon="mdi-map-marker"
                      v-model="formData.incidentLocation"
                      :label="$t('wizard.incidentLocation')"
                      data-vv-name="Incident_Location"
                      data-vv-scope="form1"
                      data-vv-as="Incident Location"
                      @keydown.enter="searchGeoService"
                      append-outer-icon="mdi-magnify"
                      @click:append-outer="searchGeoService"
                    >
                    </v-text-field>
                    <v-text-field
                      v-show="showCoordinateFields"
                      type="number"
                      prepend-icon="mdi-latitude"
                      v-model="formData.incidentLatitude"
                      :label="$t('wizard.latitude')"
                      data-vv-name="Latitude"
                      data-vv-as="Latitude"
                    >
                    </v-text-field>
                    <v-text-field
                      v-show="showCoordinateFields"
                      type="number"
                      prepend-icon="mdi-longitude"
                      v-model="formData.incidentLongitude"
                      :label="$t('wizard.longitude')"
                      data-vv-name="Longitude"
                      data-vv-as="Longitude"
                    >
                    </v-text-field>
                    <v-btn
                      class="mb-3"
                      prepend-icon="mdi-longitude"
                      readonly
                      :label="$t('wizard.getCurrentLocation')"
                      data-vv-name="Current Location"
                      data-vv-as="Current Location"
                      @click="getCurrentLocation"
                    >
                      {{ $t('wizard.getCurrentLocation') }}
                    </v-btn>
                    <v-divider></v-divider>
                    <GmapMap
                      ref="mapRef"
                      :center="center"
                      :zoom="7"
                      :options="{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: true,
                        disableDefaultUI: false,
                      }"
                      map-type-id="terrain"
                      style="width: 500px; height: 300px"
                    >
                      <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="true"
                        @click="center = m.position"
                        @dragend="markerMoved($event.latLng)"
                      />
                    </GmapMap>
                  </v-col>
                </v-row>
              </form>
            </v-stepper-content>

            <v-stepper-content step="3">
              <form data-vv-scope="form1">
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-account-circle"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.incidentDetails') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    !isOnlyClaimToEntrustedProperty &&
                    !isOnlyWarehouseKeepersLiability
                  "
                >
                  <v-col>
                    <v-radio-group
                      v-model="formData.cargoType"
                      row
                      :label="$t('wizard.cargoType')"
                    >
                      <v-radio
                        :label="$t('wizard.vehicle')"
                        value="Vehicle"
                      ></v-radio>
                      <v-radio
                        :label="$t('wizard.standardCargo')"
                        value="Standard cargo"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    formData.cargoType == 'Vehicle' &&
                    !isOnlyWarehouseKeepersLiability
                  "
                >
                  <!-- <v-col cols="6" sm="3" v-if="!isClient">
                    <v-text-field v-model="formData.vin" label="VIN">
                    </v-text-field>
                  </v-col> -->
                  <v-col
                    cols="6"
                    sm="12"
                    class="elevation-5"
                    ref="cargoVehicles"
                  >
                    <v-tabs
                      next-icon="mdi-arrow-right-bold-box-outline"
                      prev-icon="mdi-arrow-left-bold-box-outline"
                      show-arrows
                      v-model="tabs"
                    >
                      <v-tab @click="addNewVehicle">
                        {{ $t('wizard.addVehicle') }}
                      </v-tab>
                      <v-tab
                        :class="
                          $v.formData.cargoVehicles.$each.$iter[index].vin
                            .$error
                            ? 'error--text'
                            : ''
                        "
                        v-for="(v, index) in formData.cargoVehicles"
                        :key="index"
                        :href="`#tab-${index}`"
                      >
                        {{ $t('wizard.vehicle') }} {{ index + 1 }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabs">
                      <v-tab-item
                        v-for="(v, index) in formData.cargoVehicles"
                        :key="index"
                        :value="`tab-${index}`"
                      >
                        <v-col cols="6" sm="3">
                          <v-text-field
                            v-model="v.vin"
                            label="VIN*"
                            :error-messages="VINErrors(index)"
                            @blur="
                              $v.formData.cargoVehicles.$each.$iter[
                                index
                              ].vin.$touch()
                            "
                            @input="
                              $v.formData.cargoVehicles.$each.$iter[
                                index
                              ].vin.$touch()
                            "
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-combobox
                            v-model="v.damagedParts"
                            :items="vehicleParts"
                            :label="$t('wizard.selectDamagedVehicleParts')"
                            :error-messages="damagedVehiclePartsErrors(index)"
                            @change="checkVehicleParts"
                            multiple
                            @blur="
                              $v.formData.cargoVehicles.$each.$iter[
                                index
                              ].damagedParts.$touch()
                            "
                            @input="
                              $v.formData.cargoVehicles.$each.$iter[
                                index
                              ].damagedParts.$touch()
                            "
                          ></v-combobox>
                        </v-col>

                        <v-col cols="6" sm="12">
                          <v-text-field
                            dense
                            v-model="v.make"
                            :label="$t('wizard.make')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12">
                          <v-text-field
                            dense
                            v-model="v.model"
                            :label="$t('wizard.model')"
                          ></v-text-field>
                          <v-radio-group
                            v-model="v.condition"
                            row
                            :label="$t('wizard.vehicleCondition')"
                          >
                            <v-radio
                              :label="$t('wizard.new')"
                              value="new"
                            ></v-radio>
                            <v-radio
                              :label="$t('wizard.used')"
                              value="used"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="6" sm="12">
                          <div class="text-left">
                            <v-btn
                              color="error"
                              @click="selectVehicleToRemove(index)"
                              >{{ $t('wizard.removeVehicle') }}</v-btn
                            >
                          </div>
                        </v-col>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    formData.cargoType == 'Standard cargo' &&
                    !isOnlyClaimToEntrustedProperty &&
                    !isOnlyWarehouseKeepersLiability
                  "
                >
                  <v-col v-if="!isOnlyWarehouseKeepersLiability">
                    <v-combobox
                      v-model="formData.reportedDamage"
                      :items="standardCargoItems"
                      :label="$t('wizard.selectCargoType')"
                      @change="checkStandardCargoItems"
                      :return-object="true"
                      multiple
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    formData.cargoType == 'Standard cargo' &&
                    !isOnlyClaimToEntrustedProperty &&
                    !isOnlyWarehouseKeepersLiability
                  "
                >
                  <v-col cols="6" sm="12">
                    <v-text-field
                      type="number"
                      dense
                      v-model="formData.cargoWeight"
                      :label="$t('wizard.missingDamagedCargoWeight')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="12">
                    <v-text-field
                      type="number"
                      dense
                      v-model="formData.cargoQuantity"
                      :label="$t('wizard.missingDamagedCargoAmount')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  ref="circumstances"
                  v-if="!isOnlyWarehouseKeepersLiability"
                >
                  <v-col cols="3" sm="3">
                    <v-radio-group
                      v-if="
                        formData.cargoType == 'Standard cargo' &&
                        !isOnlyClaimToEntrustedProperty
                      "
                      :label="`${$t(`wizard.standardCargoCircumstances`)}:`"
                      v-model="formData.circumstanceId"
                    >
                      <v-radio
                        v-for="circumstance in circumstances.filter(
                          (c) => c.type == 'standard cargo'
                        )"
                        :key="circumstance.id"
                        :label="
                          $t(`circumstances[&quot;${circumstance.name}&quot;]`)
                        "
                        :value="circumstance.id"
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                      v-if="formData.cargoType == 'Vehicle'"
                      :label="`${$t(`wizard.vehicleCircumstances`)}:`"
                      v-model="formData.circumstanceId"
                    >
                      <v-radio
                        v-for="circumstance in circumstances.filter(
                          (c) => c.type == 'vehicle'
                        )"
                        :key="circumstance.id"
                        :label="
                          $t(`circumstances[&quot;${circumstance.name}&quot;]`)
                        "
                        :value="circumstance.id"
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                      ref="isOnlyClaimToEntrustedProperty"
                      v-if="
                        formData.isClaimToEntrustedProperty &&
                        !formData.isCargoClaim
                      "
                      :label="`${$t(
                        `wizard.claimToEntrustedPropertyCircumstances`
                      )}:`"
                      v-model="formData.claimToEntrustedPropertyCircumstanceId"
                    >
                      <v-radio
                        v-for="circumstance in circumstances.filter(
                          (c) => c.type == 'entrusted property'
                        )"
                        :key="circumstance.id"
                        :label="
                          $t(`circumstances[&quot;${circumstance.name}&quot;]`)
                        "
                        :value="circumstance.id"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="formData.isClaimToThirdParty">
                  <v-col>
                    <v-combobox
                      :readonly="isClient"
                      v-model="formData.damagedPropertyType"
                      :items="damagedPropertyTypes"
                      :label="$t('wizard.selectDamagedPropertyType')"
                      @change="checkDamagedPropertyTypes"
                      multiple
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="12">
                    <v-textarea
                      ref="description"
                      v-model="formData.description"
                      outlined
                      :label="
                        isOnlyWarehouseKeepersLiability
                          ? $t('wizard.incidentDescription') + '*'
                          : $t('wizard.incidentDescription')
                      "
                      :error-messages="descriptionErrors"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
              </form>
            </v-stepper-content>

            <v-stepper-content step="4">
              <form>
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-file-document"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.documents') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row v-if="formData.attachmentIds.length > 0">
                  <v-col cols="12" class="d-flex justify-end">
                    <div>
                      <v-btn
                        outlined
                        color="green lighten-1"
                        @click="downloadAllClaimFiles"
                      >
                        <v-icon> mdi-cloud-download </v-icon>
                        {{ $t('wizard.downloadAll') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    v-for="(fileCategory, index) in fileCategories"
                    :key="index"
                    cols="6"
                    lg="6"
                    :ref="
                      fileCategory === 'Carrier‘s CMR waybill'
                        ? 'carriersCmrWaybill'
                        : ''
                    "
                  >
                    <FileUpload
                      :label="translateFileCategory(fileCategory)"
                      :category="fileCategory"
                      :requiredFileCategories="
                        typeof formData.requiredFileCategories == 'string'
                          ? []
                          : formData.requiredFileCategories
                      "
                      @handleUploadedFile="handleUploadedFile"
                      @handleRequiredCategories="handleRequiredCategories"
                      :files="files"
                      :error-messages="
                        fileCategory === 'Carrier‘s CMR waybill'
                          ? carriersCmrWaybillErrors
                          : []
                      "
                    />
                    <v-list>
                      <v-list-item
                        v-for="(file, index) in categoryFiles(fileCategory)"
                        :key="index"
                        dense
                      >
                        <v-list-item-avatar>
                          <v-icon class="orange lighten-2" dark>
                            {{ fileIcon(file) }}
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="file.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="attachmentUploadDate(file)"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            v-if="!isClient"
                            icon
                            @click="selectFileForCategoryChange(file)"
                          >
                            <v-icon color="orange lighten-4"
                              >mdi-file-move</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-btn
                            v-if="file.mime.includes('image')"
                            icon
                            @click="showImageViewer(file)"
                          >
                            <v-icon color="green lighten-1"
                              >mdi-image-outline</v-icon
                            >
                          </v-btn>
                          <v-btn
                            v-if="file.mime.includes('pdf')"
                            icon
                            @click="openPDF(file)"
                          >
                            <v-icon color="red lighten-1"
                              >mdi-file-pdf-box</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-btn @click="selectFileToDelete(file)" icon>
                            <v-icon color="red lighten-1">mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-btn icon @click="downloadFile(file)">
                            <v-icon color="green lighten-1"
                              >mdi-cloud-download</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </form>
              <!-- <ThumbnailGallery :thumbnails="thumbnails" /> -->
            </v-stepper-content>
            <StageManagement
              v-if="formData.stage"
              :stage="formData.stage"
              :latestReminder="formData.latestReminder"
              @changeStage="changeStage"
            />
            <div v-for="(vError, index) in validationErrors" :key="index">
              <span
                v-if="$v.formData[vError.validationField]?.$error"
                class="error--text"
                >{{ vError.label }} {{ $t('validations.fieldHasErrors') }}
                <sup
                  ><v-icon
                    small
                    color="red lighten-2"
                    @click="setFocus(vError.step, vError.reference)"
                    >mdi-arrow-top-right-thick</v-icon
                  ></sup
                >
              </span>
            </div>
            <v-container>
              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn
                    v-if="$route.params.id"
                    to="/cmrdashboard"
                    class="ma-2"
                  >
                    <v-icon left> mdi-arrow-left </v-icon
                    >{{ $t('wizard.backToClaimList') }}</v-btn
                  >
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="$route.params.id"
                    :disabled="submitButtonDisabled"
                    class="ma-2"
                    color="success"
                    @click="submitFormData"
                    >{{ $t('wizard.saveChanges') }}</v-btn
                  >
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="
                      steps.length == currentStep && $route.params.id == null
                    "
                    :disabled="submitButtonDisabled"
                    color="primary"
                    @click="submitFormData"
                    class="ma-2"
                  >
                    {{ $t('wizard.submit') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row justify="space-between">
                <v-col cols="auto">
                  <v-btn
                    v-if="currentStep != 1"
                    color="text"
                    @click="prevStep(currentStep)"
                    class="ma-2"
                  >
                    {{ $t('wizard.previous') }}
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="steps.length > currentStep"
                    color="primary"
                    @click="nextStep(currentStep)"
                    class="ma-2"
                  >
                    {{ $t('wizard.next') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, requiredIf, alphaNum } from 'vuelidate/lib/validators'
import { mapState, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import axios from 'axios'
import VehicleForm from './VehicleForm.vue'
import FileCategoryChangeForm from './FileCategoryChangeForm.vue'
import DriverForm from './DriverForm.vue'
import ClientForm from './ClientForm.vue'
import ImageViewer from './ImageViewer.vue'
import StageManagement from './StageManagement.vue'
import InsurancePolicyForm from './InsurancePolicyForm.vue'
import Modal from './Modal.vue'
import FileUpload from './FileUpload'
import MTPLService from './../services/MTPLService'

import LatestReminder from '../components/LatestReminder.vue'

// import ThumbnailGallery from './ThumbnailGallery.vue'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  findFileCategoryTranslation,
  formatString,
  translateArray,
} from '../store/helpers'
dayjs.extend(customParseFormat)

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
}
export default {
  components: {
    VehicleForm,
    Modal,
    InsurancePolicyForm,
    FileUpload,
    DriverForm,
    ClientForm,
    StageManagement,
    ImageViewer,
    LatestReminder,
    FileCategoryChangeForm,
    // ThumbnailGallery,
  },
  data: function () {
    return {
      fileToDelete: null,
      deleteDialog: false,
      selectVehicles: [],
      selectDrivers: [],
      selectClients: [],
      selectedRadio: null,
      claimNumberButton: false,
      imageFileIndex: '',
      imageFiles: [],
      markers: [],
      center: { lat: 55.4281113, lng: 24.1197816 },
      formData: {
        clientId: '',
        vin: '',
        claimNumber: '',
        clientClaimNumber: '',
        claimingParty: '',
        insuranceProvider: '',
        insuranceClaimNumber: '',
        factualCarrier: '',
        incidentDate: '',
        amountReserve: 0,
        reportedDamage: '',
        unloadingDate: '',
        dateDeclaredForInsurer: '',
        payoutDate: '',
        amountReduced: 0,
        amountRejected: 0,
        payoutReceived: 0,
        vehicleId: '',
        vehicleCondition: 'New',

        // TODO: Check if value populates after BE and DB updates.
        cargoType: 'Vehicle',
        driverId: '',
        contacts: '',
        description: '',
        incidentLatitude: 0,
        incidentLongitude: 0,
        incidentLocation: '',
        loadingLocation: '',
        unloadLocation: '',
        attachmentIds: [],
        currencyId: 1,
        reportedDate: '',
        outcome: '',
        outcomeForEmployees: '',
        amountClaimed: '',
        make: '',
        model: '',
        allowDirectContact: false,
        reserveReceived: false,
        isUrgent: false,
        cargoWeight: 0,
        cargoQuantity: 0,
        CMRClaimType: ['cargo claim'],
        damagedPropertyType: '',
        isCargoClaim: false,
        isClaimToThirdParty: false,
        isClaimToEntrustedProperty: false,
        isFreightForwardingClaim: false,
        isWarehouseKeepersLiability: false,
        cargoVehicles: [
          {
            vin: '',
            make: '',
            model: '',
            condition: 'new',
            damagedParts: '',
          },
        ],
        requiredFileCategories: [],
      },
      dialogVehicleDelete: false,
      vehicleToRemoveIndex: null,
      blankVehicle: {
        vin: '',
        make: '',
        model: '',
        condition: 'new',
        damagedParts: '',
      },
      tabs: 'tab-0',
      stages: [{}],
      submitButtonDisabled: false,
      currentStep: 1,
      selectedVehicle: '',
      selectedDriver: '',
      selectedPolicy: '',
      selectedFile: null,
      showFileCategoryChangeModal: false,
      showInsuredVehicleModal: false,
      showDriverModal: false,
      showClientModal: false,
      showImageViewerModal: false,
      showInsurancePolicyModal: false,
      showCoordinateFields: false,
      incident_datepicker_show: false,
      reported_datepicker_show: false,
      finalInvoiceReceivedDate_datepicker_show: false,
      unloadingDate_datepicker_show: false,
      payoutDate_datepicker_show: false,
      dateDeclaredForInsurer_datepicker_show: false,
      Accident_Date: '',
      Accident_Time: '',
      Accident_Location: '',
      Owner_Email: '',
      Owner_Tel: '',
      Owner_Address: '',
      Owner_Name: '',
      Policy_Number: '',
      Accident_Description: '',
      Owner_Code: '',
      Accident_Coordinates: '',
      //Unload Location autocomplete properties
      searchUnloadLocation: null,
      selectedUnloadLocationItem: null,
      suggestedUnloadLocationItems: [],
      isUnloadLocationLoading: false,
      //Loading Location autocomplete properties
      searchLoadingLocation: null,
      selectedLoadingLocationItem: null,
      suggestedLoadingLocationItems: [],
      isLoadingLocationLoading: false,
    }
  },
  validations: {
    formData: {
      // insuranceClaimNumber: {
      //   minLength: minLength(2),
      //   required: requiredIf(function () {
      //     return (
      //       this.checkICNStages &&
      //       !this.formData.insuranceClaimNumber &&
      //       !this.isClient
      //     )
      //   }),
      // },
      driverId: {
        required: requiredIf(function () {
          return !this.isOnlyWarehouseKeepersLiability
        }),
      },
      vehicleId: {
        required: requiredIf(function () {
          return !this.isOnlyWarehouseKeepersLiability
        }),
      },
      clientId: {
        required,
      },
      circumstanceId: {
        required: requiredIf(function () {
          return (
            !this.isOnlyClaimToEntrustedProperty &&
            !this.isOnlyWarehouseKeepersLiability
          )
        }),
      },
      claimToEntrustedPropertyCircumstanceId: {
        required: requiredIf(function () {
          return this.isOnlyClaimToEntrustedProperty
        }),
      },
      unloadingDate: {
        required: requiredIf(function () {
          return !this.isOnlyWarehouseKeepersLiability
        }),
      },
      incidentDate: {
        required: requiredIf(function () {
          return this.isOnlyWarehouseKeepersLiability
        }),
      },
      loadingLocation: {
        required: requiredIf(function () {
          return !this.isOnlyWarehouseKeepersLiability
        }),
      },
      unloadLocation: {
        required: requiredIf(function () {
          return !this.isOnlyWarehouseKeepersLiability
        }),
      },
      description: {
        required: requiredIf(function () {
          if (this.circumstances && this.formData.circumstanceId) {
            let circumstance = this.circumstances.filter(
              (c) => c.id == this.formData.circumstanceId && c.name == 'Other'
            )
            return circumstance.length > 0
          } else if (this.isOnlyWarehouseKeepersLiability) {
            return true
          } else {
            return false
          }
        }),
      },
      cargoVehicles: {
        $each: {
          vin: {
            required: requiredIf(function () {
              if (!this.isOnlyWarehouseKeepersLiability) {
                return this.formData.cargoType == 'Vehicle'
              } else {
                return false
              }
            }),
            alphaNum,
          },
          damagedParts: {
            required: requiredIf(function () {
              if (!this.isOnlyWarehouseKeepersLiability) {
                return this.formData.cargoType == 'Vehicle'
              } else {
                return false
              }
            }),
          },
        },
      },

      carriersCmrWaybill: {
        required: requiredIf(function () {
          if (this.isOnlyWarehouseKeepersLiability) {
            return false
          } else {
            return (
              this.files.filter(
                (file) => file.category == 'Carrier‘s CMR waybill'
              ).length === 0 && !this.formData.id
            )
          }
        }),
      },
    },
  },
  methods: {
    handleRequiredCategories(payload) {
      if (payload.value) {
        if (!this.formData.requiredFileCategories.includes(payload.category)) {
          this.formData.requiredFileCategories.push(payload.category)
        }
      } else if (!payload.value) {
        this.formData.requiredFileCategories =
          this.formData.requiredFileCategories.filter(
            (c) => c != payload.category
          )
      }
    },
    selectVehicleToRemove(id) {
      this.vehicleToRemoveIndex = id
      this.toggleVehicleRemoveDialog()
    },

    toggleVehicleRemoveDialog() {
      if (this.dialogVehicleDelete) {
        this.vehicleToRemoveIndex = null
      }
      this.dialogVehicleDelete = !this.dialogVehicleDelete
    },
    deleteVehicleConfirm() {
      if (this.vehicleToRemoveIndex != null) {
        this.formData.cargoVehicles.splice(this.vehicleToRemoveIndex, 1)
      }
      this.toggleVehicleRemoveDialog()
    },
    addNewVehicle() {
      let newVehicle = Object.assign({}, this.blankVehicle)
      this.formData.cargoVehicles.push(newVehicle)
      this.$nextTick(() => {
        this.tabs = `tab-${this.formData.cargoVehicles.length - 1}`
      })
    },
    checkStandardCargoItems(items) {
      items.forEach((item) => {
        if (
          item != '' &&
          !this.standardCargoItems.map((i) => i.value).includes(item)
        ) {
          if (typeof item != Object) {
            item = {
              text: item,
              value: item,
            }
          }
          this.standardCargoItems.unshift(item)
        }
      })
    },
    checkVehicleParts(items) {
      items.forEach((item) => {
        if (item != '' && !this.vehicleParts.includes(item)) {
          this.vehicleParts.unshift(item)
        }
      })
    },
    setFocus(stepId, ref) {
      this.currentStep = stepId
      this.$refs[ref].focus()
    },

    async getClaimNumber() {
      if (!this.isClient) {
        this.$store
          .dispatch('mtpl/getClaimNumber', 2)
          .then((response) => {
            this.formData.claimNumber = response.data.claimNumber
            this.claimNumberButton = true
          })
          .catch(() => {
            let snackbar = {
              text: this.$i18n.t('snackbar.couldNotRetrieveClaimNumber'),
              color: 'error',
            }
            this.$store.dispatch('snackbar/addSnackbar', snackbar, {
              root: true,
            })
          })
      }
    },

    checkDamagedPropertyTypes(items) {
      items.forEach((item) => {
        if (item != '' && !this.damagedPropertyTypes.includes(item)) {
          this.damagedPropertyTypes.unshift(item)
        }
      })
    },
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        this.success,
        this.error,
        options
      )
    },
    success(pos) {
      var crd = pos.coords
      this.formData.incidentLatitude = crd.latitude
      this.formData.incidentLongitude = crd.longitude
      let coords = { lat: crd.latitude, lng: crd.longitude }
      this.reverseGeocode(coords)
      this.setMarker(coords)
      this.center = coords
    },
    error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`)
    },
    reverseGeocode(coords) {
      let { lat, lng } = coords
      const geocoder = new window.google.maps.Geocoder()

      geocoder.geocode(
        { location: { lat: lat, lng: lng } },
        (results, status) => {
          if (status === 'OK') {
            this.formData.incidentLocation = results[0].formatted_address
            this.setMarker({
              lat: this.formData.incidentLatitude,
              lng: this.formData.incidentLongitude,
            })
            this.center = {
              lat: this.formData.incidentLatitude,
              lng: this.formData.incidentLongitude,
            }
          }
        }
      )
    },
    setMarker(coords) {
      let marker = {}
      marker.position = coords
      this.markers = []
      this.markers.push(marker)
    },
    markerMoved(event) {
      let lat = event.lat()
      let lng = event.lng()
      this.formData.incidentLatitude = lat
      this.formData.incidentLongitude = lng
      const geocoder = new window.google.maps.Geocoder()

      geocoder.geocode(
        { location: { lat: lat, lng: lng } },
        (results, status) => {
          if (status === 'OK') {
            this.formData.incidentLocation = results[0].formatted_address
            this.center = { lat, lng }
          }
        }
      )
    },
    searchGeoService() {
      const geocoder = new window.google.maps.Geocoder()

      geocoder.geocode(
        { address: this.$refs.searchText.value },
        (results, status) => {
          if (status === 'OK') {
            this.formData.incidentLatitude = results[0].geometry.location.lat()
            this.formData.incidentLongitude = results[0].geometry.location.lng()
            this.formData.incidentLocation = results[0].formatted_address
            this.setMarker({
              lat: this.formData.incidentLatitude,
              lng: this.formData.incidentLongitude,
            })
            this.center = {
              lat: this.formData.incidentLatitude,
              lng: this.formData.incidentLongitude,
            }
          }
        }
      )
    },
    nextStep(stepIndex) {
      if (this.currentStep <= this.steps.length)
        this.currentStep = stepIndex + 1
    },
    prevStep(stepIndex) {
      if (this.currentStep > 1) this.currentStep = stepIndex - 1
    },
    toggleClientFormModal() {
      this.showClientModal = !this.showClientModal
    },
    toggleImageViewerModal() {
      this.imageFileIndex = null
      this.showImageViewerModal = !this.showImageViewerModal
    },
    showImageViewer(file) {
      this.imageFileIndex = this.imageFiles.findIndex((i) => i.id == file.id)
      this.showImageViewerModal = true
    },
    openPDF(file) {
      axios({
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/${file.id}/download`, // File URL Goes Here
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const pdfUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )
        window.open(pdfUrl, '_blank')
      })
    },
    toggleInsuredVehicleModal() {
      this.showInsuredVehicleModal = !this.showInsuredVehicleModal
    },
    toggleDriverModal() {
      this.showDriverModal = !this.showDriverModal
    },
    toggleFileCategoryChangeModal() {
      this.showFileCategoryChangeModal = !this.showFileCategoryChangeModal
      if (this.showFileCategoryChangeModal === false) {
        // this.selectedFile = null
      }
    },
    selectFileForCategoryChange(file) {
      this.selectedFile = file
      this.toggleFileCategoryChangeModal()
    },
    capitalizeFirstLetter(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
      return ''
    },
    submitFormData() {
      this.$v.formData.$touch()
      if (this.$v.$invalid && !this.submitButtonDisabled) {
        this.submitButtonDisabled = true
        setTimeout(() => {
          this.submitButtonDisabled = false
        }, 5000)
      } else {
        const dataToSubmit = Object.assign({}, this.formData)
        if (!this.isAdmin && this.formData.claimNumber === '') {
          delete dataToSubmit.claimNumber
        }
        if (dataToSubmit.incidentDate != '') {
          dataToSubmit.incidentDate = new Date(
            dayjs(this.formData.incidentDate)
          )
        } else {
          delete dataToSubmit.incidentDate
        }

        if (
          dataToSubmit.finalInvoiceReceivedDate != '' &&
          dataToSubmit.finalInvoiceReceivedDate
        ) {
          dataToSubmit.finalInvoiceReceivedDate = new Date(
            dayjs(this.formData.finalInvoiceReceivedDate)
          )
        } else {
          // delete dataToSubmit.finalInvoiceReceivedDate
          dataToSubmit.finalInvoiceReceivedDate = null
        }

        if (dataToSubmit.payoutDate != '' && dataToSubmit.payoutDate) {
          dataToSubmit.payoutDate = new Date(dayjs(this.formData.payoutDate))
        } else {
          delete dataToSubmit.payoutDate
        }

        if (
          dataToSubmit.dateDeclaredForInsurer != '' &&
          dataToSubmit.dateDeclaredForInsurer
        ) {
          dataToSubmit.dateDeclaredForInsurer = new Date(
            dayjs(this.formData.dateDeclaredForInsurer)
          )
        } else {
          delete dataToSubmit.dateDeclaredForInsurer
        }

        if (dataToSubmit.unloadingDate != '' && dataToSubmit.unloadingDate) {
          dataToSubmit.unloadingDate = new Date(
            dayjs(this.formData.unloadingDate)
          )
        } else {
          delete dataToSubmit.unloadingDate
        }

        dataToSubmit.reportedDate = new Date(dayjs(this.formData.reportedDate))

        dataToSubmit.amountClaimed = parseFloat(
          (dataToSubmit.amountClaimed * 100).toFixed()
        )

        dataToSubmit.amountReserve = parseFloat(
          (dataToSubmit.amountReserve * 100).toFixed()
        )

        dataToSubmit.amountRejected = parseFloat(
          (dataToSubmit.amountRejected * 100).toFixed()
        )
        dataToSubmit.amountReduced = parseFloat(
          (dataToSubmit.amountReduced * 100).toFixed()
        )
        dataToSubmit.payoutReceived = parseFloat(
          (dataToSubmit.payoutReceived * 100).toFixed()
        )

        if (dataToSubmit.cargoQuantity != 0) {
          dataToSubmit.cargoQuantity = parseInt(dataToSubmit.cargoQuantity)
        } else {
          delete dataToSubmit.cargoQuantity
        }

        dataToSubmit.cargoWeight = parseFloat(
          (dataToSubmit.cargoWeight * 100).toFixed()
        )

        delete dataToSubmit.followers
        delete dataToSubmit.stageChangeDate
        if (typeof dataToSubmit.incidentLatitude == 'string') {
          dataToSubmit.incidentLatitude = parseFloat(
            dataToSubmit.incidentLatitude
          )
        }
        if (typeof dataToSubmit.incidentLongitude == 'string') {
          dataToSubmit.incidentLongitude = parseFloat(
            dataToSubmit.incidentLongitude
          )
        }
        dataToSubmit.damagedPropertyType = JSON.stringify(
          dataToSubmit.damagedPropertyType
        )
        dataToSubmit.reportedDamage = JSON.stringify(
          dataToSubmit.reportedDamage
        )
        dataToSubmit.requiredFileCategories = JSON.stringify(
          dataToSubmit.requiredFileCategories
        )
        if (dataToSubmit.cargoType == 'Standard cargo') {
          dataToSubmit.cargoVehicles = []
        }
        if (this.isOnlyWarehouseKeepersLiability) {
          delete dataToSubmit.vehicleId
          delete dataToSubmit.driverId
        }

        if (
          dataToSubmit.cargoVehicles &&
          dataToSubmit.cargoVehicles.length > 0
        ) {
          dataToSubmit.cargoVehicles = dataToSubmit.cargoVehicles.map((v) => {
            let damagedParts = JSON.stringify(v.damagedParts)
            return {
              ...v,
              damagedParts: damagedParts,
            }
          })
        }

        if (dataToSubmit.amountReserve != 0 && this.isClient) {
          dataToSubmit.reserveReceived = true
        } else if (dataToSubmit.amountReserve == 0 && this.isClient) {
          dataToSubmit.reserveReceived = false
        }

        if (this.$route.path != '/CMRWizard') {
          this.$store.dispatch('mtpl/updateClaim', dataToSubmit)
        } else if (!this.formData.id) {
          dataToSubmit.reportedDate = new Date()
          delete dataToSubmit.status

          // TODO: remove hardcode stageId
          dataToSubmit.stageId = 17
          this.$store.dispatch('mtpl/createCMRClaim', dataToSubmit)
        }
        this.$store.dispatch('site/preventNavigation', false)
      }
    },
    setColour() {
      if (!this.isClient) {
        let colourCode = this.claim.colour ? null : 'FF0000'
        let payload = {
          id: this.claim.id,
          colour: colourCode,
        }
        this.$store.dispatch('mtpl/changeColour', payload)
      }
    },
    selectClient(value) {
      this.formData.clientId = value
      this.$store.dispatch('vehicle/getVehicles', value)
      this.$store.dispatch('driver/getDrivers', value)
    },
    selectDriver(selected) {
      if (selected?.value) this.formData.driverId = selected.value
    },
    selectVehicle(selected) {
      if (selected?.value) this.formData.vehicleId = selected.value
    },
    handleUploadedFile(fileId) {
      this.formData.attachmentIds.push(fileId)
      this.$store.dispatch('file/getFile', fileId)
      this.$store.dispatch('site/preventNavigation', this.unsavedFileMessage)
    },
    saveInsuredVehicle(formData) {
      const vehicle = Object.assign({}, formData)
      vehicle.year = parseInt(vehicle.year)
      vehicle.clientId = this.formData.clientId
      this.$store
        .dispatch('vehicle/createVehicle', vehicle)
        .then((vehicleId) => {
          this.selectVehicle({ value: vehicleId })
        })
      this.toggleInsuredVehicleModal()
    },
    saveDriver(formData) {
      const driver = Object.assign({}, formData)
      driver.clientId = this.formData.clientId
      if (driver.birthDate) driver.birthDate = new Date(driver.birthDate)
      this.$store.dispatch('driver/createDriver', driver).then((driverId) => {
        this.selectDriver({ value: driverId })
      })
      this.toggleDriverModal()
    },
    saveClient(formData) {
      this.$store.dispatch('client/createClient', formData).then((clientId) => {
        this.selectClient(clientId)
      })
      this.toggleClientFormModal()
    },
    saveInsurancePolicy(values) {
      this.vehicles.push({
        text: values,
        value: values,
      })
      this.selectedPolicy = values
      this.insurancePolicyModal()
    },
    insurancePolicyModal() {
      this.showInsurancePolicyModal = !this.showInsurancePolicyModal
    },
    toggleInsurancePolicyModal() {
      this.showInsurancePolicyModal = !this.showInsurancePolicyModal
    },
    vehicleRegistrationChange(files) {
      this.form
      if (files.length > 0)
        files.map((f) => {
          f.category = 'vehicleRegistration'
          return f
        })
      this.formData.attachmentIds.push(files)
      console.table(this.formData.attachments)
    },
    categoryFiles(category) {
      return this.files.filter((f) => f.category == category)
    },
    fileIcon(file) {
      if (file.mime.includes('image')) {
        return 'mdi-image-outline'
      } else {
        return 'mdi-clipboard-text'
      }
    },
    attachmentUploadDate(file) {
      return dayjs(file.createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    downloadFile(file) {
      axios({
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/${file.id}/download`, // File URL Goes Here
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data], { type: file.mime })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${file.name}.${file.extension}`
        link.click()
      })
    },
    downloadAllClaimFiles() {
      axios({
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/claims/${this.formData.id}/download`,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `claim${
          this.formData.claimNumber
            ? this.formData.claimNumber
            : this.formData.id
        }files.zip`
        link.click()
      })
    },
    //TODO: unlinking should remove file from S3?
    unlinkFile(file) {
      this.formData.attachmentIds.filter((a) => a != file.id)
      if (this.$route.params.id) {
        this.formData.attachmentIds = this.formData.attachmentIds.filter(
          (f) => f != file.id
        )
        this.submitFormData()
      }
      this.deleteDialog = false
      this.$store.dispatch('file/removeFile', file)
    },
    selectFileToDelete(file) {
      this.fileToDelete = file
      this.deleteDialog = true
    },
    changeStage(stageId) {
      if (stageId != null) {
        this.$store
          .dispatch('mtpl/changeStage', {
            stageId,
            claimId: this.$route.params.id,
          })
          .then((response) => {
            let { stage } = response.data.claim
            this.$nextTick(() => {
              this.formData.stage = stage
              this.formData.stageId = stage.id
            })
          })
      }
    },
    VINErrors(index) {
      const errors = []
      !this.$v.formData.cargoVehicles.$each[index].vin.required &&
        errors.push(this.$i18n.t('validations.VINRequired'))
      !this.$v.formData.cargoVehicles.$each[index].vin.alphaNum &&
        errors.push(this.$i18n.t('validations.VINAlphanumeric'))
      return errors
    },
    damagedVehiclePartsErrors(index) {
      const errors = []
      !this.$v.formData.cargoVehicles.$each[index].damagedParts.required &&
        errors.push(this.$i18n.t('validations.damagedPartsRequired'))
      return errors
    },
    translateFileCategory(text) {
      return findFileCategoryTranslation(text)
    },
    async queryUnloadLocation(value) {
      if (value && value.length > 3) {
        this.isUnloadLocationLoading = true
        try {
          // Simulate an asynchronous request with a timeout
          const response = await MTPLService.getSuggestions(
            this.formData.clientId,
            'unloadLocation',
            value
          )
          // Assuming response.data contains an array of suggested items
          this.suggestedUnloadLocationItems = response.data.suggestions
        } catch (error) {
          console.error('Error fetching suggestion data:', error)
          // Handle error
        } finally {
          this.isUnloadLocationLoading = false
        }
      }
    },
    async queryLoadingLocation(value) {
      if (value && value.length > 3) {
        this.isLoadingLocationLoading = true
        try {
          // Simulate an asynchronous request with a timeout
          const response = await MTPLService.getSuggestions(
            this.formData.clientId,
            'loadingLocation',
            value
          )
          // Assuming response.data contains an array of suggested items
          this.suggestedLoadingLocationItems = response.data.suggestions
        } catch (error) {
          console.error('Error fetching suggestion data:', error)
          // Handle error
        } finally {
          this.isLoadingLocationLoading = false
        }
      }
    },
  },

  computed: {
    ...mapState({
      claim: (state) => state.mtpl.claim,
      clients: (state) => state.client.clients,
      vehicles: (state) => state.vehicle.vehicles,
      drivers: (state) => state.driver.drivers,
      files: (state) => state.file.files,
      circumstances: (state) => state.circumstance.circumstances,
    }),
    ...mapGetters({
      stagesById: 'stage/stagesById',
      stageById: 'stage/stageById',
      role: 'user/role',
    }),
    fileCategories() {
      let fileCategoryArray = []
      if (this.isOnlyWarehouseKeepersLiability) {
        fileCategoryArray.push('Proof that cargo was delivered to a warehouse')
      } else {
        fileCategoryArray.push('Carrier‘s CMR waybill')
      }

      fileCategoryArray.push('Rescue invoice')
      if (
        this.formData.cargoType == 'Standard cargo' &&
        !this.isOnlyWarehouseKeepersLiability
      ) {
        fileCategoryArray.push('Carrier‘s CMR insurance policy')
        if (
          this.formData.isCargoClaim ||
          (this.formData.isCargoClaim &&
            this.formData.isClaimToEntrustedProperty)
        ) {
          fileCategoryArray.push('Consignee‘s CMR waybill')
        }
        fileCategoryArray.push('Incident declaration/police')
        fileCategoryArray.push('Vehicle/trailer registration documents')
        fileCategoryArray.push('Transportation order')
        if (
          this.formData.isCargoClaim ||
          (this.formData.isCargoClaim &&
            this.formData.isClaimToEntrustedProperty)
        ) {
          fileCategoryArray.push('Defect act')
        }
        fileCategoryArray.push('Photos')
        if (
          !this.formData.isCargoClaim &&
          this.formData.isClaimToEntrustedProperty
        ) {
          fileCategoryArray.push('Repair estimate/repair invoice')
          fileCategoryArray.push(
            'Repair estimate/repair invoice of the trailer'
          )
        }
        if (this.isOnlyClaimToEntrustedProperty) {
          fileCategoryArray.push('Written driver‘s explanation')
        }
        if (
          this.formData.isCargoClaim ||
          (this.formData.isCargoClaim &&
            this.formData.isClaimToEntrustedProperty)
        ) {
          fileCategoryArray.push("Written driver's explanation")
          fileCategoryArray.push('Packing list')
          fileCategoryArray.push('Invoice of the cargo')
          fileCategoryArray.push('Survey report')
        }
        fileCategoryArray.push('Claim/Final invoice')
        fileCategoryArray.push('Correspondence with claimant')
        fileCategoryArray.push('Other documents')
      }
      if (
        this.formData.cargoType == 'Vehicle' &&
        !this.isOnlyWarehouseKeepersLiability
      ) {
        if (
          this.formData.isCargoClaim ||
          !this.formData.isClaimToEntrustedProperty
        ) {
          fileCategoryArray.push('Consignee‘s CMR waybill')
        }
        fileCategoryArray.push('Incident declaration/police')
        fileCategoryArray.push('Vehicle/trailer registration documents')
        fileCategoryArray.push('Transportation order')
        fileCategoryArray.push('Defect act')
        fileCategoryArray.push('Photos')
        fileCategoryArray.push('Written driver‘s explanation')
        fileCategoryArray.push('Repair estimate')
        fileCategoryArray.push('Repair invoice')
        fileCategoryArray.push('Survey report')
        fileCategoryArray.push('Vehicle‘s condition report before loading')
        fileCategoryArray.push('Vehicle‘s commercial invoice')
        fileCategoryArray.push('Claim/Final invoice')
        fileCategoryArray.push('Correspondence with claimant')
        fileCategoryArray.push('Other documents')
      }
      if (this.isOnlyWarehouseKeepersLiability) {
        fileCategoryArray.push('Photos')
        fileCategoryArray.push('Correspondence with claimant')
        fileCategoryArray.push('Commercial invoice')
        fileCategoryArray.push('Other documents')
      }
      return fileCategoryArray
    },
    vehicleParts() {
      return [
        {
          text: this.$i18n.t('vehicleParts.wholeVehicle'),
          value: 'Whole vehicle',
        },
        {
          text: this.$i18n.t('vehicleParts.frontBumper'),
          value: 'Front bumper',
        },
        {
          text: this.$i18n.t('vehicleParts.bottomOfTheFrontBumper'),
          value: 'Bottom of the front bumper',
        },
        { text: this.$i18n.t('vehicleParts.rearBumper'), value: 'Rear bumper' },
        {
          text: this.$i18n.t('vehicleParts.bottomOfTheRearBumper'),
          value: 'Bottom of the rear bumper',
        },
        { text: this.$i18n.t('vehicleParts.hood'), value: 'Hood' },
        { text: this.$i18n.t('vehicleParts.headlights'), value: 'Headlights' },
        { text: this.$i18n.t('vehicleParts.taillights'), value: 'Taillights' },
        { text: this.$i18n.t('vehicleParts.roof'), value: 'Roof' },
        { text: this.$i18n.t('vehicleParts.frontWing'), value: 'Front wing' },
        { text: this.$i18n.t('vehicleParts.rearWing'), value: 'Rear wing' },
        { text: this.$i18n.t('vehicleParts.sill'), value: 'Sill' },
        {
          text: this.$i18n.t('vehicleParts.luggageLid/Tailgate'),
          value: 'Luggage lid / tailgate',
        },
        { text: this.$i18n.t('vehicleParts.windshield'), value: 'Windshield' },
        { text: this.$i18n.t('vehicleParts.backWindow'), value: 'Back window' },
        { text: this.$i18n.t('vehicleParts.vent'), value: 'Vent' },
        {
          text: this.$i18n.t('vehicleParts.frontDoorRightSide'),
          value: 'Front door right side',
        },
        {
          text: this.$i18n.t('vehicleParts.rearDoorRightSide'),
          value: 'Rear door right side',
        },
        {
          text: this.$i18n.t('vehicleParts.frontDoorLeftSide'),
          value: 'Front door left side',
        },
        {
          text: this.$i18n.t('vehicleParts.rearDoorLeftSide'),
          value: 'Rear door left side',
        },
        { text: this.$i18n.t('vehicleParts.underbody'), value: 'Underbody' },
        { text: this.$i18n.t('vehicleParts.rim'), value: 'Rim' },
        { text: this.$i18n.t('vehicleParts.tyre'), value: 'Tyre' },
        { text: this.$i18n.t('vehicleParts.mirror'), value: 'Mirror' },
        { text: this.$i18n.t('vehicleParts.antenna'), value: 'Antenna' },
      ]
    },
    standardCargoItems() {
      return [
        {
          text: this.$i18n.t('standardCargoItems.foodItems'),
          value: 'Food items',
        },
        {
          text: this.$i18n.t('standardCargoItems.non-alcoholicBeverages'),
          value: 'Non-alcoholic beverages',
        },
        {
          text: this.$i18n.t('standardCargoItems.alcoholicBeverages'),
          value: 'Alcoholic beverages',
        },
        {
          text: this.$i18n.t('standardCargoItems.metal/steel'),
          value: 'Metal/steel',
        },
        { text: this.$i18n.t('standardCargoItems.wood'), value: 'Wood' },
        {
          text: this.$i18n.t('standardCargoItems.sanitaryProducts'),
          value: 'Sanitary products',
        },
        {
          text: this.$i18n.t('standardCargoItems.medicialTechnology'),
          value: 'Medicial technology',
        },
        {
          text: this.$i18n.t('standardCargoItems.pharmaceuticalProducts'),
          value: 'Pharmaceutical products',
        },
        {
          text: this.$i18n.t('standardCargoItems.cosmeticItems'),
          value: 'Cosmetic items',
        },
        {
          text: this.$i18n.t('standardCargoItems.electronics'),
          value: 'Electronics',
        },
        {
          text: this.$i18n.t('standardCargoItems.chemicalProducts'),
          value: 'Chemical products',
        },
        {
          text: this.$i18n.t('standardCargoItems.constructionMaterials'),
          value: 'Construction materials',
        },
        {
          text: this.$i18n.t('standardCargoItems.fabric/clothingItems'),
          value: 'Fabric/Clothing items',
        },
        {
          text: this.$i18n.t('standardCargoItems.agriculturalProducts'),
          value: 'Agricultural products',
        },
        { text: this.$i18n.t('standardCargoItems.paper'), value: 'Paper' },
        {
          text: this.$i18n.t('standardCargoItems.furniture'),
          value: 'Furniture',
        },
        {
          text: this.$i18n.t('standardCargoItems.glassItems'),
          value: 'Glass items',
        },
      ]
    },
    damagedPropertyTypes() {
      return [
        {
          text: this.$i18n.t('wizard.damagedPropertyTypes.vehicle'),
          value: 'Vehicle',
        },
        {
          text: this.$i18n.t('wizard.damagedPropertyTypes.truck'),
          value: 'Truck',
        },
        {
          text: this.$i18n.t('wizard.damagedPropertyTypes.fence'),
          value: 'Fence',
        },
        {
          text: this.$i18n.t('wizard.damagedPropertyTypes.roadBarrier'),
          value: 'Road barrier',
        },
        {
          text: this.$i18n.t('wizard.damagedPropertyTypes.gates'),
          value: 'Gates',
        },
      ]
    },
    steps() {
      return [
        {
          index: 1,
          title: this.$i18n.t('wizard.steps.general'),
        },
        {
          index: 2,
          title: this.$i18n.t('wizard.steps.location'),
        },
        {
          index: 3,
          title: this.$i18n.t('wizard.steps.details'),
        },
        {
          index: 4,
          title: this.$i18n.t('wizard.steps.documents'),
        },
      ]
    },
    validationErrors() {
      return [
        {
          validationField: 'clientId',
          label: this.$i18n.t('wizard.client'),
          step: 1,
          reference: 'clientId',
        },
        {
          validationField: 'driverId',
          label: this.$i18n.t('wizard.driver'),
          step: 1,
          reference: 'driverId',
        },
        {
          validationField: 'vehicleId',
          label: this.$i18n.t('wizard.vehicle'),
          step: 1,
          reference: 'vehicleId',
        },
        {
          validationField: 'unloadingDate',
          label: this.$i18n.t('wizard.unloadingDate'),
          step: 1,
          reference: 'unloadingDate',
        },
        {
          validationField: 'loadingLocation',
          label: this.$i18n.t('wizard.loadingLocation'),
          step: 2,
          reference: 'loadingLocation',
        },
        {
          validationField: 'unloadLocation',
          label: this.$i18n.t('wizard.unloadLocation'),
          step: 2,
          reference: 'unloadLocation',
        },
        {
          validationField: 'cargoVehicles',
          label: this.$i18n.t('wizard.cargoVehicles'),
          step: 3,
          reference: 'cargoVehicles',
        },
        {
          validationField: 'circumstanceId',
          label: this.$i18n.t('wizard.circumstance'),
          step: 3,
          reference: 'circumstances',
        },
        {
          validationField: 'claimToEntrustedPropertyCircumstanceId',
          label: this.$i18n.t('wizard.circumstance'),
          step: 3,
          reference: 'isOnlyClaimToEntrustedProperty',
        },
        {
          validationField: 'description',
          label: this.$i18n.t('wizard.incidentDescription'),
          step: 3,
          reference: 'description',
        },
        {
          validationField: 'carriersCmrWaybill',
          label: this.$i18n.t('wizard.carriersCmrWaybill'),
          step: 4,
          reference: 'carriersCmrWaybill',
        },
        {
          validationField: 'incidentDate',
          label: this.$i18n.t('wizard.incidentDate'),
          step: 1,
          reference: 'incidentDate',
        },
      ]
    },
    isOnlyClaimToEntrustedProperty() {
      return (
        !this.formData.isCargoClaim &&
        !this.formData.isClaimToThirdParty &&
        !this.formData.isFreightForwardingClaim &&
        this.formData.isClaimToEntrustedProperty
      )
    },
    isOnlyWarehouseKeepersLiability() {
      return (
        !this.formData.isCargoClaim &&
        !this.formData.isClaimToThirdParty &&
        !this.formData.isFreightForwardingClaim &&
        !this.formData.isClaimToEntrustedProperty &&
        this.formData.isWarehouseKeepersLiability
      )
    },
    insuranceProviderList() {
      return [
        'Atralo',
        'BTA',
        'Bunda',
        'ERGO',
        'TT club',
        'Marine underwriting services',
        'LD',
        'Lampe&Schwartze',
        this.$i18n.t('insuranceProviders.culpritsInsuranceCompany'),
        this.$i18n.t('insuranceProviders.contractNotConcludedViaInsuranceBee'),
        this.$i18n.t('insuranceProviders.other'),
      ]
    },
    created() {
      return this.$route.path != '/CMRWizard'
    },
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    isAdmin() {
      return this.$store.getters['user/role'] == 'admin'
    },
    unsavedFileMessage() {
      return this.$i18n.t('dialogMessages.unsavedFile')
    },

    checkICNStages() {
      let requiredStages = [
        'Agreed, but unpaid',
        'Missing contact details',
        'Large claim',
        'Reported by insurer',
        'Negotiation failed',
        'Registered with insurer',
      ]
      if (this.claim?.stage != null) {
        return requiredStages.includes(this.claim.stage.displayName)
      } else {
        return false
      }
    },

    driverErrors() {
      const errors = []
      if (!this.$v.formData.driverId.$dirty) return errors
      !this.$v.formData.driverId.required &&
        errors.push(this.$i18n.t('validations.driverRequired'))
      return errors
    },
    vehicleErrors() {
      const errors = []
      if (!this.$v.formData.vehicleId.$dirty) return errors
      !this.$v.formData.vehicleId.required &&
        errors.push(this.$i18n.t('validations.vehicleRequired'))
      return errors
    },
    clientErrors() {
      const errors = []
      if (!this.$v.formData.clientId.$dirty) return errors
      !this.$v.formData.clientId.required &&
        errors.push(this.$i18n.t('validations.clientRequired'))
      return errors
    },
    unloadingDateErrors() {
      const errors = []
      if (!this.$v.formData.unloadingDate.$dirty) return errors
      !this.$v.formData.unloadingDate.required &&
        errors.push(this.$i18n.t('validations.unloadDateRequired'))
      return errors
    },
    incidentDateErrors() {
      const errors = []
      if (!this.$v.formData.incidentDate.$dirty) return errors
      !this.$v.formData.incidentDate.required &&
        errors.push(this.$i18n.t('validations.incidentDateRequired'))
      return errors
    },
    descriptionErrors() {
      const errors = []
      !this.$v.formData.description.required &&
        errors.push(this.$i18n.t('validations.incidentDescriptionRequired'))
      return errors
    },
    loadingLocationErrors() {
      const errors = []
      if (!this.$v.formData.loadingLocation.$dirty) return errors
      !this.$v.formData.loadingLocation.required &&
        errors.push(this.$i18n.t('validations.loadingLocationRequired'))
      return errors
    },
    unloadLocationErrors() {
      const errors = []
      if (!this.$v.formData.unloadLocation.$dirty) return errors
      !this.$v.formData.unloadLocation.required &&
        errors.push(this.$i18n.t('validations.unloadLocationRequired'))
      return errors
    },
    carriersCmrWaybillErrors() {
      const errors = []
      if (!this.$v.formData.carriersCmrWaybill.$dirty) return errors
      this.files.filter((file) => file.category == 'Carrier‘s CMR waybill')
        .length === 0 &&
        errors.push(this.$i18n.t('validations.carriersCmrWaybillRequired'))
      return errors
    },

    colourButtonTitle() {
      // !claim?.colour ? 'Pažymėti' : 'Nuimti žymėjimą'
      return !this.claim?.colour
        ? this.$i18n.t('wizard.markColour')
        : this.$i18n.t('wizard.unmarkColour')
    },
  },
  watch: {
    '$i18n.locale'() {
      this.formData.reportedDamage = translateArray(
        this.formData.reportedDamage,
        'standardCargoItems'
      )
      this.formData.damagedPropertyType = translateArray(
        this.formData.damagedPropertyType,
        'wizard.damagedPropertyTypes'
      )
      this.formData.cargoVehicles = this.formData.cargoVehicles.map((v) => {
        return {
          ...v,
          damagedParts: translateArray(v.damagedParts, 'vehicleParts'),
        }
      })
    },

    'formData.isWarehouseKeepersLiability'(value) {
      if (value) {
        this.$nextTick(() => {
          this.formData.isCargoClaim = false
          this.formData.isClaimToEntrustedProperty = false
          this.formData.isClaimToThirdParty = false
          this.formData.isFreightForwardingClaim = false
        })
      }
    },

    searchUnloadLocation(value) {
      value &&
        value !== this.formData.unloadLocation &&
        this.queryUnloadLocation(value)
    },
    searchLoadingLocation(value) {
      value &&
        value !== this.formData.loadingLocation &&
        this.queryLoadingLocation(value)
    },
    'formData.isClaimToEntrustedProperty'() {
      if (this.formData.isClaimToEntrustedProperty) {
        this.$nextTick(() => {
          this.formData.cargoType = 'Standard cargo'
        })
      }
    },
    claim() {
      this.formData = Object.assign({}, this.claim)
      if (this.formData.incidentDate == '0001-01-01T00:00:00Z') {
        this.formData.incidentDate = ''
      } else {
        this.formData.incidentDate = dayjs(this.claim.incidentDate).format(
          'YYYY-MM-DD'
        )
      }

      if (this.formData.reportedDate == '0001-01-01T00:00:00Z') {
        this.formData.reportedDate = ''
      } else {
        this.formData.reportedDate = dayjs(this.claim.reportedDate).format(
          'YYYY-MM-DD'
        )
      }

      if (this.formData.finalInvoiceReceivedDate) {
        if (this.formData.finalInvoiceReceivedDate == '0001-01-01T00:00:00Z') {
          this.formData.finalInvoiceReceivedDate = ''
        } else {
          this.formData.finalInvoiceReceivedDate = dayjs(
            this.claim.finalInvoiceReceivedDate
          ).format('YYYY-MM-DD')
        }
      }
      if (this.formData.payoutDate) {
        if (this.formData.payoutDate == '0001-01-01T00:00:00Z') {
          this.formData.payoutDate = ''
        } else {
          this.formData.payoutDate = dayjs(this.claim.payoutDate).format(
            'YYYY-MM-DD'
          )
        }
      }
      if (this.formData.unloadingDate) {
        if (this.formData.unloadingDate == '0001-01-01T00:00:00Z') {
          this.formData.unloadingDate = ''
        } else {
          this.formData.unloadingDate = dayjs(this.claim.unloadingDate).format(
            'YYYY-MM-DD'
          )
        }
      }

      if (
        this.formData.damagedPropertyType !== '' &&
        this.formData.damagedPropertyType !== '""' &&
        this.formData.damagedPropertyType &&
        typeof this.formData.damagedPropertyType != 'object'
      ) {
        let damagedPropertyTypesArray = JSON.parse(
          this.formData.damagedPropertyType
        )
        this.formData.damagedPropertyType = damagedPropertyTypesArray
        damagedPropertyTypesArray.forEach((item) => {
          if (item != '' && !this.damagedPropertyTypes.includes(item)) {
            this.damagedPropertyTypes.unshift(item)
          }
        })
        this.formData.damagedPropertyType = translateArray(
          this.formData.damagedPropertyType,
          'wizard.damagedPropertyTypes'
        )
      } else if (this.formData.damagedPropertyType === '""') {
        this.formData.damagedPropertyType = []
      }

      if (!this.claim.cargoQuantity) {
        this.formData.cargoQuantity = 0
      }

      if (this.formData.dateDeclaredForInsurer) {
        if (this.formData.dateDeclaredForInsurer == '0001-01-01T00:00:00Z') {
          this.formData.dateDeclaredForInsurer = ''
        } else {
          this.formData.dateDeclaredForInsurer = dayjs(
            this.claim.dateDeclaredForInsurer
          ).format('YYYY-MM-DD')
        }
      }

      if (this.formData.cargoType) {
        this.formData.cargoType = this.capitalizeFirstLetter(
          this.formData.cargoType
        )
      }

      if (this.formData.vehicleCondition) {
        this.formData.vehicleCondition = this.capitalizeFirstLetter(
          this.formData.vehicleCondition
        )
      }

      if (
        this.formData.reportedDamage !== '' &&
        this.formData.reportedDamage !== '""' &&
        this.formData.reportedDamage &&
        typeof this.formData.reportedDamage != 'object'
      ) {
        let reportedDamageArray = JSON.parse(this.formData.reportedDamage)
        reportedDamageArray = reportedDamageArray.map((reportedDamage) => {
          if (reportedDamage instanceof Object) {
            if (
              this.$i18n.te(
                `standardCargoItems["${formatString(reportedDamage.value)}"]`
              )
            ) {
              return {
                text: this.$i18n.t(
                  `standardCargoItems["${formatString(reportedDamage.value)}"]`
                ),
                value: reportedDamage.value,
              }
            } else {
              return {
                text: reportedDamage.text,
                value: reportedDamage.value,
              }
            }
          } else {
            if (
              this.$i18n.te(
                `standardCargoItems["${formatString(reportedDamage)}"]`
              )
            ) {
              return {
                text: this.$i18n.t(
                  `standardCargoItems["${formatString(reportedDamage)}"]`
                ),
                value: reportedDamage,
              }
            } else {
              return {
                text: reportedDamage,
                value: reportedDamage,
              }
            }
          }
        })
        this.formData.reportedDamage = reportedDamageArray
        reportedDamageArray.forEach((item) => {
          if (item != '' && !this.standardCargoItems.includes(item)) {
            if (this.formData.cargoType == 'Standard cargo') {
              this.standardCargoItems.unshift(item)
            } else if (this.formData.cargoType == 'Vehicle') {
              this.vehicleParts.unshift(item)
            }
          }
        })
      } else if (this.formData.reportedDamage === '""') {
        this.formData.reportedDamage = []
      }

      if (isNaN(this.formData.cargoWeight)) {
        this.formData.cargoWeight = 0
      }

      if (isNaN(this.formData.amountClaimed)) {
        this.formData.amountClaimed = 0
      }

      if (isNaN(this.formData.amountReserve)) {
        this.formData.amountReserve = 0
      }
      if (isNaN(this.formData.amountReduced)) {
        this.formData.amountReduced = 0
      }
      if (isNaN(this.formData.amountRejected)) {
        this.formData.amountRejected = 0
      }
      if (isNaN(this.formData.payoutReceived)) {
        this.formData.payoutReceived = 0
      }

      this.formData.cargoWeight = (this.formData.cargoWeight / 100).toFixed(2)
      this.formData.amountReserve = (this.formData.amountReserve / 100).toFixed(
        2
      )
      this.formData.amountClaimed = (this.formData.amountClaimed / 100).toFixed(
        2
      )
      this.formData.amountReduced = (this.formData.amountReduced / 100).toFixed(
        2
      )

      this.formData.amountRejected = (
        this.formData.amountRejected / 100
      ).toFixed(2)
      this.formData.payoutReceived = (
        this.formData.payoutReceived / 100
      ).toFixed(2)

      this.$store.dispatch('vehicle/getVehicles', this.claim.clientId)
      this.$store.dispatch('driver/getDrivers', this.claim.clientId)
      if (this.formData.attachmentIds.length > 0) {
        this.$store.dispatch('file/getFiles', this.formData.attachmentIds)
      }
      if (this.formData.incidentLatitude && this.formData.incidentLongitude) {
        this.setMarker({
          lat: this.formData.incidentLatitude,
          lng: this.formData.incidentLongitude,
        })
        this.center = {
          lat: this.formData.incidentLatitude,
          lng: this.formData.incidentLongitude,
        }
      }

      if (!this.claim.cargoVehicles && this.claim.cargoType == 'vehicle') {
        this.formData.cargoVehicles = [
          {
            vin: '',
            make: '',
            model: '',
            condition: 'new',
            damagedParts: '',
          },
        ]
      }

      if (
        this.formData.cargoVehicles &&
        this.formData.cargoVehicles.length > 0
      ) {
        this.formData.cargoVehicles = this.formData.cargoVehicles.map((v) => {
          if (
            v.damagedParts !== '' &&
            v.damagedParts !== '""' &&
            v.damagedParts &&
            typeof v.damagedParts != 'object'
          ) {
            let damagedParts = JSON.parse(v.damagedParts)
            return {
              ...v,
              damagedParts: translateArray(damagedParts, 'vehicleParts'),
            }
          } else {
            return {
              ...v,
              damagedParts: [],
            }
          }
        })

        this.formData.cargoVehicles.forEach((v) => {
          v.damagedParts.forEach((item) => {
            if (item != '' && !this.vehicleParts.includes(item)) {
              this.vehicleParts.unshift(item)
            }
          })
        })
      }

      //Required file categories loading
      if (
        this.formData.requiredFileCategories !== '' &&
        this.formData.requiredFileCategories !== '""' &&
        this.formData.requiredFileCategories &&
        typeof this.formData.requiredFileCategories != 'object'
      ) {
        let requiredFileCategoriesArray = JSON.parse(
          this.formData.requiredFileCategories
        )
        this.formData.requiredFileCategories = requiredFileCategoriesArray
      } else if (!this.claim.requiredFileCategories) {
        this.formData.requiredFileCategories = []
      }
    },
    clients() {
      this.selectClients = this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
    vehicles() {
      this.selectVehicles = this.vehicles.map((v) => ({
        text: v.registration,
        value: v.id,
      }))
    },
    drivers() {
      this.selectDrivers = this.drivers.map((v) => ({
        text: v.name,
        value: v.id,
      }))
    },
    files() {
      // this.fileCategories = [...new Set(this.files.map((f) => f.category))]
      this.$nextTick(() => {
        this.imageFiles = this.files.filter((f) => f.mime.includes('image'))
      })
    },
  },
  mounted() {
    this.$store.dispatch('client/getClients')
    this.$store.dispatch('circumstance/getCircumstances', 2)

    if (this.$route.params.id && !this.isClient) {
      this.$store.dispatch('stage/getStages', 2)
    }
  },
  unmounted() {
    this.$store.dispatch('driver/clearDrivers')
    this.$store.dispatch('vehicle/clearVehicles')
    this.$store.dispatch('file/clearFiles')
  },
  destroyed() {
    this.$store.dispatch('driver/clearDrivers')
    this.$store.dispatch('vehicle/clearVehicles')
    this.$store.dispatch('file/clearFiles')
    this.$store.dispatch('circumstance/clearCircumstances')
  },
}
</script>

<style lang="scss" scoped>
.vue-map-container {
  display: block;
  margin: auto;
}
.stepper__enlarge {
  flex-basis: 250px;
}
</style>
