export const exportCsv = (data) => {
  let csvContent = 'data:text/csv;charset=utf-8,'
  var universalBOM = '\uFEFF'
  csvContent += universalBOM
  csvContent +=
    // 'Tipas;Kliento kodas;Valstybinis numeris;Apdraustų dienų skaičius;Įvykių skaičius;Įvykiai;\n'
    'Claim;Client;Reported Date;Expectation/loss document not provided;Acknowledged responsibility;Vehicle Registration;Trailer Registration;Insurance Claim Number;Incident Date;Driver;Transferred to Representatives;Description;Stage;Stage Change Date;Location;Insurance Provider;Circumstance;Outcome;Duration;Suspended Duration;Followers\n'
  csvContent += data
    .map((item) =>
      Object.values(item)
        .map((el) => {
          if (typeof el === 'string' && el.includes('#')) {
            return el.replaceAll('#', ' ')
          } else {
            return el
          }
        })
        .join(';')
    )
    .join('\n')
    .replace(/(^\[)|(\]$)/gm, '')

  const csvData = encodeURI(csvContent)
  return csvData
}
