export const exportCsv = (data) => {
  let csvContent = 'data:text/csv;charset=utf-8,'
  var universalBOM = '\uFEFF'
  csvContent += universalBOM
  csvContent +=
    // 'Tipas;Kliento kodas;Valstybinis numeris;Apdraustų dienų skaičius;Įvykių skaičius;Įvykiai;\n'
    'Claim;Client;Registration;Incident Date;Circumstance;Reported Date;Driver;Description;Location;Stage;Stage Change Date;ICN;Register in Insurance;Claimed;Calculations Received Date;Paid;Contacts;Contacts entered;Duration;Followers\n'

  csvContent += data
    .map((item) =>
      Object.values(item)
        .map((el) => {
          if (typeof el === 'string' && el.includes('#')) {
            return el.replaceAll('#', ' ')
          } else {
            return el
          }
        })
        .join(';')
    )
    .join('\n')
    .replace(/(^\[)|(\]$)/gm, '')

  const csvData = encodeURI(csvContent)
  return csvData
}
